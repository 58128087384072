import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import PageNotFound from 'screens/PageNotFound';
import { AuthStateStorageService, LoadingSpinner, Header, useFetch } from 'common.ui';
import RoutePaths from 'RoutePaths';
import PasswordReset from 'screens/password-reset/PasswordReset';
import { useMsal } from 'auth/MsalContext';
import { flattenRoutes, isValidRoutePath, RouteItem, toSidebarItem } from 'helpers/routing';
import ClearCookies from 'screens/dev-tools/ClearCookies';
import { MainContent } from 'components/pageTemplate/MainContent';
import { SidebarItem } from 'components/pageTemplate/Sidebar';
import { HamburgerMenu } from 'components/pageTemplate/HamburgerMenu';
import LoginInternal from 'screens/login-internal/LoginInternal';
import LoginStatsforvalteren from 'screens/login-statsforvalteren/LoginStatsforvalteren';
import PostLogout from 'screens/post-logout/PostLogout';
import { useAccessControlList } from 'hooks/useAccessControlList';
import { useUser } from 'contexts/UserContext';
import { Footer } from 'components/pageTemplate/Footer';
import { useTranslation } from 'react-i18next';
import { Language } from 'api/minside';
import UnderMaintenance from 'screens/undermaintenance/UnderMaintenance';
import { profileClient } from 'api/MinSideClients';
import NoAccessPage from 'screens/NoAccessPage';
import { RouteConfig } from './RouteConfig';

import logoImage from './assets/images/MdirLogo_Main.svg';
import logoImageEn from './assets/images/MdirLogo_Main_En.svg';
import accountImage from './assets/images/person-header.png';
import Environment from 'Environment';

const allRoutes = flattenRoutes(RouteConfig);

type IHamburgerMenu = {
  routes: SidebarItem[];
};

const Routes = () => {
  const { t } = useTranslation('components', { keyPrefix: 'pageTemplate.header' });
  const [acl] = useAccessControlList();
  const hasAccess = (item: RouteItem) => {
    if (!acl) return item.requiredPermission === undefined;
    if (!item.requiredPermission) return true;
    return acl.some((a) => a === item.requiredPermission);
  };
  const sidebarItems = RouteConfig.filter((x) => hasAccess(x) && !x.hidden).map((rc) => toSidebarItem(rc));
  const { isAuthenticated, user, authInProgress, logout, login } = useMsal();
  const [currentUser] = useFetch(async () => profileClient.apiProfileCurrentGet(), undefined, false, [user]);

  const { isLoading, hasAnyAccessRights, preferredLangauge } = useUser();

  const homeRoute = allRoutes.filter((x) => x.link === RoutePaths.profile)[0];
  const getLocalizedLogo = (lang: Language) => (lang === Language.English ? logoImageEn : logoImage);

  const location = useLocation();
  const isMaintenancePage = location.pathname === RoutePaths.underMaintenance;

  if (isMaintenancePage) return <UnderMaintenance />;

  return authInProgress || isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Header
        // centered
        applicationName='Min side'
        logoImage={getLocalizedLogo(preferredLangauge)}
        accountImage={accountImage}
        authInProgress={authInProgress}
        isAuthenticated={isAuthenticated}
        activeUser={user}
        logout={logout}
        login={login}
        loginText={t('loginText')}
        logoutText={t('logoutText')}
        showTestBanner={Environment.Current === 'test'}
        hamburgerMenu={<HamburgerMenu routes={sidebarItems} />}
      />

      <MainContent routes={sidebarItems}>
        <>
          <Switch>
            {allRoutes.map((r) => {
              if (currentUser?.employer === '' && (!r.link.includes('profile') || r.link.includes('validate')))
                return <Route exact key={r.link} path={r.link} component={() => <NoAccessPage />} />;
              return <Route exact key={r.link} path={r.link} component={hasAccess(r) ? r.component : () => <div />} />;
            })}
            {/* Redirect from password reset */}
            <Route exact key={RoutePaths.passwordReset} path={RoutePaths.passwordReset} component={PasswordReset} />
            <Route exact key={RoutePaths.clearCookies} path={RoutePaths.clearCookies} component={ClearCookies} />
            <Route exact key={RoutePaths.loginInternal} path={RoutePaths.loginInternal} component={LoginInternal} />
            <Route
              exact
              key={RoutePaths.loginStatsforvalteren}
              path={RoutePaths.loginStatsforvalteren}
              component={LoginStatsforvalteren}
            />
            <Route exact key={RoutePaths.postLogout} path={RoutePaths.postLogout} component={PostLogout} />

            <Route
              exact
              key={RoutePaths.home}
              path={RoutePaths.home}
              render={() => {
                if (!isAuthenticated) return React.createElement(homeRoute.component);
                const redirectTo = AuthStateStorageService.getRedirectionState();

                if (redirectTo && isValidRoutePath(redirectTo)) {
                  AuthStateStorageService.setRedirectionState(undefined);
                  return <Redirect to={redirectTo} />;
                }

                if (currentUser?.employer === '') return <Redirect to={RoutePaths.profileConfigureEmployer} />;

                return hasAnyAccessRights ? (
                  <Redirect to={RoutePaths.profile} />
                ) : (
                  <Redirect to={RoutePaths.accessRequest} />
                );
              }}
            />

            {/* 404 route */}
            <Route component={PageNotFound} />
          </Switch>

          <Footer centered logoImage={getLocalizedLogo(preferredLangauge)} />
        </>
      </MainContent>
    </>
  );
};

export { Routes };
