/* eslint-disable no-console */
import Environment from 'Environment';
import { AuthService } from '../auth/AuthService';
import { FetchParams, Middleware, RequestContext } from './minside/runtime';

class AddTokenMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  async pre?(context: RequestContext): Promise<FetchParams | void> {
    const token = await AuthService.getAccessTokenSilent();

    if (!token) {
      console.log('Could not obtain access token.');
      throw new Error('Could not obtain access token.');
    } else if (Environment.Current !== 'production') {
      console.log({ token });
    }

    const headers = context.init.headers as Record<string, string>;
    headers.Authorization = `Bearer ${token}`;

    return context;
  }
}

export default AddTokenMiddleware;
