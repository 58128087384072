/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleDto
 */
export interface ArticleDto {
    /**
     * 
     * @type {number}
     * @memberof ArticleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    ingress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    descriptionHtml?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    category?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleDto
     */
    hideTableOfContents?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    seoTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    seoDescription?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDto
     */
    changed?: Date;
}

export function ArticleDtoFromJSON(json: any): ArticleDto {
    return ArticleDtoFromJSONTyped(json, false);
}

export function ArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'ingress': !exists(json, 'ingress') ? undefined : json['ingress'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'descriptionHtml': !exists(json, 'descriptionHtml') ? undefined : json['descriptionHtml'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'hideTableOfContents': !exists(json, 'hideTableOfContents') ? undefined : json['hideTableOfContents'],
        'seoTitle': !exists(json, 'seoTitle') ? undefined : json['seoTitle'],
        'seoDescription': !exists(json, 'seoDescription') ? undefined : json['seoDescription'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'changed': !exists(json, 'changed') ? undefined : (new Date(json['changed'])),
    };
}

export function ArticleDtoToJSON(value?: ArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'name': value.name,
        'title': value.title,
        'ingress': value.ingress,
        'icon': value.icon,
        'descriptionHtml': value.descriptionHtml,
        'category': value.category,
        'hideTableOfContents': value.hideTableOfContents,
        'seoTitle': value.seoTitle,
        'seoDescription': value.seoDescription,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'changed': value.changed === undefined ? undefined : (value.changed.toISOString()),
    };
}


