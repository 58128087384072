/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationResponsePagedResult,
    ApplicationResponsePagedResultFromJSON,
    ApplicationResponsePagedResultToJSON,
    ApplicationSearchOrderBy,
    ApplicationSearchOrderByFromJSON,
    ApplicationSearchOrderByToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiApplicationsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: ApplicationSearchOrderBy;
}

/**
 * 
 */
export class ApplicationsApi extends runtime.BaseAPI {

    /**
     */
    async apiApplicationsGetRaw(requestParameters: ApiApplicationsGetRequest): Promise<runtime.ApiResponse<ApplicationResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationsGet(requestParameters: ApiApplicationsGetRequest): Promise<ApplicationResponsePagedResult> {
        const response = await this.apiApplicationsGetRaw(requestParameters);
        return await response.value();
    }

}
