/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    UserMigrationApplicationResponse,
    UserMigrationApplicationResponseFromJSON,
    UserMigrationApplicationResponseToJSON,
    UserMigrationMigrateRequest,
    UserMigrationMigrateRequestFromJSON,
    UserMigrationMigrateRequestToJSON,
    UserMigrationQueryRequest,
    UserMigrationQueryRequestFromJSON,
    UserMigrationQueryRequestToJSON,
    UserMigrationQueryResponse,
    UserMigrationQueryResponseFromJSON,
    UserMigrationQueryResponseToJSON,
    UserMigrationStatusResponse,
    UserMigrationStatusResponseFromJSON,
    UserMigrationStatusResponseToJSON,
    UserMigrationStatusUpdateRequest,
    UserMigrationStatusUpdateRequestFromJSON,
    UserMigrationStatusUpdateRequestToJSON,
} from '../models';

export interface ApiUserMigrationMigratePostRequest {
    userMigrationMigrateRequest?: UserMigrationMigrateRequest;
}

export interface ApiUserMigrationQueryPostRequest {
    userMigrationQueryRequest?: UserMigrationQueryRequest;
}

export interface ApiUserMigrationStatusPutRequest {
    userMigrationStatusUpdateRequest?: UserMigrationStatusUpdateRequest;
}

/**
 * 
 */
export class UserMigrationApi extends runtime.BaseAPI {

    /**
     */
    async apiUserMigrationApplicationsGetRaw(): Promise<runtime.ApiResponse<Array<UserMigrationApplicationResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-migration/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserMigrationApplicationResponseFromJSON));
    }

    /**
     */
    async apiUserMigrationApplicationsGet(): Promise<Array<UserMigrationApplicationResponse>> {
        const response = await this.apiUserMigrationApplicationsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiUserMigrationMigratePostRaw(requestParameters: ApiUserMigrationMigratePostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-migration/migrate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMigrationMigrateRequestToJSON(requestParameters.userMigrationMigrateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserMigrationMigratePost(requestParameters: ApiUserMigrationMigratePostRequest): Promise<void> {
        await this.apiUserMigrationMigratePostRaw(requestParameters);
    }

    /**
     */
    async apiUserMigrationQueryPostRaw(requestParameters: ApiUserMigrationQueryPostRequest): Promise<runtime.ApiResponse<Array<UserMigrationQueryResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-migration/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMigrationQueryRequestToJSON(requestParameters.userMigrationQueryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserMigrationQueryResponseFromJSON));
    }

    /**
     */
    async apiUserMigrationQueryPost(requestParameters: ApiUserMigrationQueryPostRequest): Promise<Array<UserMigrationQueryResponse>> {
        const response = await this.apiUserMigrationQueryPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserMigrationStatusGetRaw(): Promise<runtime.ApiResponse<UserMigrationStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-migration/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMigrationStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserMigrationStatusGet(): Promise<UserMigrationStatusResponse> {
        const response = await this.apiUserMigrationStatusGetRaw();
        return await response.value();
    }

    /**
     */
    async apiUserMigrationStatusPutRaw(requestParameters: ApiUserMigrationStatusPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-migration/status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserMigrationStatusUpdateRequestToJSON(requestParameters.userMigrationStatusUpdateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserMigrationStatusPut(requestParameters: ApiUserMigrationStatusPutRequest): Promise<void> {
        await this.apiUserMigrationStatusPutRaw(requestParameters);
    }

}
