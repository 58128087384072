/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    OrganizationGroupResponsePagedResult,
    OrganizationGroupResponsePagedResultFromJSON,
    OrganizationGroupResponsePagedResultToJSON,
    OrganizationGroupSearchOrderBy,
    OrganizationGroupSearchOrderByFromJSON,
    OrganizationGroupSearchOrderByToJSON,
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeToJSON,
    OrganizationResponsePagedResult,
    OrganizationResponsePagedResultFromJSON,
    OrganizationResponsePagedResultToJSON,
    OrganizationSearchOrderBy,
    OrganizationSearchOrderByFromJSON,
    OrganizationSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiOrganizationGroupsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: OrganizationGroupSearchOrderBy;
}

export interface ApiOrganizationGroupsOrganizationsGetRequest {
    organizationGroupIds?: Array<number>;
    organizationGroupType?: OrganizationGroupType;
    isActive?: boolean;
    filterByUserCreation?: boolean;
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: OrganizationSearchOrderBy;
}

/**
 * 
 */
export class OrganizationGroupsApi extends runtime.BaseAPI {

    /**
     */
    async apiOrganizationGroupsGetRaw(requestParameters: ApiOrganizationGroupsGetRequest): Promise<runtime.ApiResponse<OrganizationGroupResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationGroupResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationGroupsGet(requestParameters: ApiOrganizationGroupsGetRequest): Promise<OrganizationGroupResponsePagedResult> {
        const response = await this.apiOrganizationGroupsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationGroupsOrganizationsGetRaw(requestParameters: ApiOrganizationGroupsOrganizationsGetRequest): Promise<runtime.ApiResponse<OrganizationResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.organizationGroupIds) {
            queryParameters['organizationGroupIds'] = requestParameters.organizationGroupIds;
        }

        if (requestParameters.organizationGroupType !== undefined) {
            queryParameters['organizationGroupType'] = requestParameters.organizationGroupType;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.filterByUserCreation !== undefined) {
            queryParameters['filterByUserCreation'] = requestParameters.filterByUserCreation;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationGroupsOrganizationsGet(requestParameters: ApiOrganizationGroupsOrganizationsGetRequest): Promise<OrganizationResponsePagedResult> {
        const response = await this.apiOrganizationGroupsOrganizationsGetRaw(requestParameters);
        return await response.value();
    }

}
