import { INavigationClient, NavigationOptions } from '@azure/msal-browser';
import { History } from 'history';

export class MsalNavigationClient implements INavigationClient {
  private push: (path: string, state?: History.PoorMansUnknown) => void | undefined;

  constructor(pushCallback: (path: string, state?: History.PoorMansUnknown) => void) {
    this.push = pushCallback;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
    if (this.push !== undefined) {
      const absoluteUrl = new URL(url);
      this.push(absoluteUrl.pathname);
    }

    return Promise.resolve(false);
  }

  // eslint-disable-next-line class-methods-use-this
  navigateExternal(url: string, options: NavigationOptions): Promise<boolean> {
    if (options.noHistory) {
      window.location.replace(url);
    } else {
      window.location.href = url;
    }

    return Promise.resolve(true);
  }
}
