import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonLink, LoadingSpinner, SelectOption, useBoundForm } from 'common.ui';
import {
  GenericValidationError,
  UserAuthenticationConstraint,
  UserMigrationApplicationResponse,
  UserMigrationQueryResponse,
  UserMigrationStatus
} from 'api/minside';
import { Steps } from 'screens/user-migration/MigrationWizard';
import { userMigrationClient } from 'api/MinSideClients';
import { Row, Col } from 'react-bootstrap';

import './MigrationWizard.scss';
import { useTranslation } from 'react-i18next';

type IProps = {
  applications: UserMigrationApplicationResponse[];
  applicationOptions: SelectOption<UserMigrationApplicationResponse>[];
  onMigrationCompleted: (status: UserMigrationStatus) => void;
  onQueryCompleted: (userData: UserDataForm, accesses: UserMigrationQueryResponse[]) => void;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
};

export type UserDataForm = {
  applicationId: string | null | undefined;
  username: string;
  password: string;
};

const initialState: UserDataForm = {
  applicationId: undefined,
  username: '',
  password: ''
};

function MigrationWizardSelection({ applicationOptions, setStep, onMigrationCompleted, onQueryCompleted }: IProps) {
  const { t } = useTranslation('components', { keyPrefix: 'userMigration.migrationWizardSelection' });
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [values, setValues] = useState<UserDataForm>(initialState);
  const [currentApplication, setCurrentApplication] = useState<UserMigrationApplicationResponse>();
  const [requiresIdPorten, setRequiresIdPorten] = useState<boolean>(false);

  const OnFormSubmit = useCallback(
    async (submitModel: UserDataForm) => {
      setValues(submitModel);
      const request = {
        userMigrationQueryRequest: {
          applicationId: submitModel.applicationId,
          username: submitModel.username,
          password: submitModel.password
        }
      };
      try {
        const result = await userMigrationClient.apiUserMigrationQueryPost(request);
        setApiErrors(undefined);
        onQueryCompleted(submitModel, result);
      } catch (e) {
        if (e instanceof Response && e.json) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [onQueryCompleted]
  );

  const { form, isLoading, Dropdown, FormContainer, Input, DisplayErrors } = useBoundForm<UserDataForm>({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  useEffect(() => {
    const idPorten = currentApplication?.userAuthenticationConstraint === UserAuthenticationConstraint.IdPortenAlways;
    setRequiresIdPorten(idPorten);
    if (idPorten) {
      console.log('The selected application requires ID-porten authentication');
    }
  }, [currentApplication]);

  return (
    <>
      <FormContainer form={form}>
        <Row>
          <Col sm={12} lg={8}>
            <p>{t('p1')}</p>
            <DisplayErrors form={form} />
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={6}>
            <Dropdown
              form={form}
              name='applicationId'
              label={t('lblApplicationIdDropdown')}
              placeholder=''
              readonly={isLoading}
              options={applicationOptions}
              onChangeSelection={(_k, value) => {
                const app = value as UserMigrationApplicationResponse;
                setCurrentApplication(app);
              }}
            />
          </Col>
        </Row>
        {requiresIdPorten && (
          <>
            <Row>
              <Col sm={12} lg={6}>
                <div className='idPortenBox'>
                  <h6>{t('idPortenDisclaimer.title')}</h6>
                  <p>{t('idPortenDisclaimer.text')}</p>
                </div>
              </Col>
            </Row>
            <ButtonLink inline text={t('idPortenDisclaimer.btnBack')} onClick={() => setStep(Steps.intro)} />
          </>
        )}
        {!requiresIdPorten && currentApplication && (
          <>
            <Row>
              <Col sm={12} lg={6}>
                <Input form={form} name='username' label={t('lblUsername')} />
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={6}>
                <Input form={form} name='password' type='password' label={t('lblPassword')} />
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={8}>
                <p>{t('p2')}</p>
              </Col>
            </Row>

            <Button type='submit' styleType='dark' text={t('btnSubmit')} disabled={isLoading} />
            <Button
              styleType='light'
              text={t('btnCancel')}
              disabled={isLoading}
              onClick={async () => {
                await onMigrationCompleted(UserMigrationStatus.Postponed);
                setStep(Steps.completed);
              }}
            />
          </>
        )}
        {isLoading && <LoadingSpinner />}
      </FormContainer>
    </>
  );
}

export default MigrationWizardSelection;
