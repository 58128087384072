import { SelectOption, useFetch } from 'common.ui';
import { UserMigrationApplicationResponse } from 'api/minside';
import { userMigrationClient } from 'api/MinSideClients';

export type ApplicationsResult = [
  SelectOption<UserMigrationApplicationResponse>[] | undefined,
  UserMigrationApplicationResponse[] | undefined
];

export function useMigrationWizardApplications(): ApplicationsResult {
  const [fetchedApplications] = useFetch<ApplicationsResult>(
    () => fetchApplications(),
    [undefined, undefined] as ApplicationsResult,
    false,
    []
  );

  const fetchApplications = async (): Promise<ApplicationsResult> => {
    const data = await userMigrationClient.apiUserMigrationApplicationsGet();

    const apps = data.sort((o1, o2) =>
      (o1.description || o1.name || '').localeCompare(o2.description || o2.name || '')
    );
    const options = apps.map(
      (app) =>
        ({
          id: app.applicationId,
          text: app.description || app.name,
          value: app
        } as SelectOption<UserMigrationApplicationResponse>)
    );

    return [options, apps];
  };

  return fetchedApplications;
}
