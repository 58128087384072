import Page from 'components/page/Page';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function UnderMaintenance() {
  const { t } = useTranslation('pages', { keyPrefix: 'underMaintenance' });
  return (
    <Container>
      <Row>
        <Col className='mt-4'>
          <Page header={t('title')}>
            <p> {t('disclaimer')} </p>
          </Page>
        </Col>
      </Row>
    </Container>
  );
}

export default UnderMaintenance;
