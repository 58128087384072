/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationResponse,
    ApplicationResponseFromJSON,
    ApplicationResponseToJSON,
    MinSideAccessControl,
    MinSideAccessControlFromJSON,
    MinSideAccessControlToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    UserOrganizationGroupResponse,
    UserOrganizationGroupResponseFromJSON,
    UserOrganizationGroupResponseToJSON,
} from '../models';

/**
 * 
 */
export class MeApi extends runtime.BaseAPI {

    /**
     */
    async apiMeAccessControlListGetRaw(): Promise<runtime.ApiResponse<Array<MinSideAccessControl>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/me/access-control-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MinSideAccessControlFromJSON));
    }

    /**
     */
    async apiMeAccessControlListGet(): Promise<Array<MinSideAccessControl>> {
        const response = await this.apiMeAccessControlListGetRaw();
        return await response.value();
    }

    /**
     */
    async apiMeApplicationsGetRaw(): Promise<runtime.ApiResponse<Array<ApplicationResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/me/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationResponseFromJSON));
    }

    /**
     */
    async apiMeApplicationsGet(): Promise<Array<ApplicationResponse>> {
        const response = await this.apiMeApplicationsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiMeOrgnaizationGroupsGetRaw(): Promise<runtime.ApiResponse<Array<UserOrganizationGroupResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/me/orgnaization-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserOrganizationGroupResponseFromJSON));
    }

    /**
     */
    async apiMeOrgnaizationGroupsGet(): Promise<Array<UserOrganizationGroupResponse>> {
        const response = await this.apiMeOrgnaizationGroupsGetRaw();
        return await response.value();
    }

}
