import React from 'react';
import Page from 'components/page/Page';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ApplicationResponse, OrganizationResponse, PermissionResponse, RoleResponse } from 'api/minside';
import { useTranslation } from 'react-i18next';

export type Props = {
  application?: ApplicationResponse;
  role?: RoleResponse;
  permission?: PermissionResponse;
  organization?: OrganizationResponse;
};

function RequestAccessConfirmation({ application, role, permission, organization }: Props) {
  const { t } = useTranslation('components');
  const appName = application?.description || application?.name;
  const accessName = role?.name || permission?.name;
  return (
    <Page header={t('access.requestAccessConfirmation.title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            <p>
              {t('access.requestAccessConfirmation.summary.p1')} &quot;{appName}&quot;
              {accessName ? ` ${t('access.requestAccessConfirmation.summary.p2')} "${accessName}"` : ''}
              {organization ? ` ${t('access.requestAccessConfirmation.summary.p3')} "${organization.name}"` : ''}.
            </p>
            <p>{t('access.requestAccessConfirmation.statusAndNextSteps.p1')}</p>
            <p>{t('access.requestAccessConfirmation.statusAndNextSteps.p2')}</p>
            <ul>
              <li>{t('access.requestAccessConfirmation.statusAndNextSteps.l1')}</li>
              <li>{t('access.requestAccessConfirmation.statusAndNextSteps.l2')}</li>
              <li>{t('access.requestAccessConfirmation.statusAndNextSteps.l3')}</li>
            </ul>
            <p>{t('access.requestAccessConfirmation.statusAndNextSteps.p3')}</p>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default RequestAccessConfirmation;
