/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMigrationMigrateRoleRequest,
    UserMigrationMigrateRoleRequestFromJSON,
    UserMigrationMigrateRoleRequestFromJSONTyped,
    UserMigrationMigrateRoleRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMigrationMigrateRequest
 */
export interface UserMigrationMigrateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserMigrationMigrateRequest
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationMigrateRequest
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationMigrateRequest
     */
    password?: string | null;
    /**
     * 
     * @type {Array<UserMigrationMigrateRoleRequest>}
     * @memberof UserMigrationMigrateRequest
     */
    roles?: Array<UserMigrationMigrateRoleRequest> | null;
}

export function UserMigrationMigrateRequestFromJSON(json: any): UserMigrationMigrateRequest {
    return UserMigrationMigrateRequestFromJSONTyped(json, false);
}

export function UserMigrationMigrateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationMigrateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(UserMigrationMigrateRoleRequestFromJSON)),
    };
}

export function UserMigrationMigrateRequestToJSON(value?: UserMigrationMigrateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'username': value.username,
        'password': value.password,
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(UserMigrationMigrateRoleRequestToJSON)),
    };
}


