import { useMsal } from 'auth/MsalContext';
import { profileClient } from 'api/MinSideClients';
import React, { useEffect, useState } from 'react';
import { useFetch } from 'common.ui';
import { Language } from 'api/minside';
import i18n, { defaultLanguage, supportedLanguages } from 'localization/LanguageConfig';
import { LanguageStorageService } from 'localization/LanguageStorageService';

export interface IUserContext {
  hasAnyAccessRights: boolean;
  isLoading: boolean;
  preferredLangauge: Language;
  changePreferredLanguage: (lang: Language) => Promise<void>;
}

export const UserContext = React.createContext<IUserContext | undefined>(undefined);

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a MsalProvider');
  }
  return context;
};

export const UserProvider = ({ children }: { children: any }) => {
  const [preferredLangauge, setPreferredLanguage] = useState<Language>(defaultLanguage.id);
  const fetchUserAccesses = async (): Promise<boolean> => {
    const userAccesses = await profileClient.apiProfileCurrentPermissionsGet();
    return userAccesses?.some((x) => x) ?? false;
  };
  const getUserPreferredLanguage = async (): Promise<Language> => {
    let lang: Language;
    if (isAuthenticated) {
      const response = await profileClient.apiProfileCurrentPreferredLanguageGet();
      // this fallback is just for type safety as Enums are always generated as nullable
      // language will always exist in the backend as it is non-nullable there
      lang = response.preferredLanguage ?? defaultLanguage.id;
    } else {
      const storedLanguagePreference = LanguageStorageService.getPreferredLanguageOrDefault();
      lang = storedLanguagePreference.id;
    }

    setPreferredLanguage(lang);
    return lang;
  };

  const { isAuthenticated } = useMsal();
  const [hasAnyAccessRights, isLoadingAccessRights] = useFetch<boolean>(fetchUserAccesses, false, true, [
    isAuthenticated
  ]);
  const [, isLoadingPreferredLanguage] = useFetch<Language>(getUserPreferredLanguage, defaultLanguage.id, true, [
    isAuthenticated
  ]);

  const changePreferredLanguage = async (lang: Language) => {
    setPreferredLanguage(lang);
    if (isAuthenticated)
      await profileClient.apiProfileCurrentPreferredLanguagePut({
        updateUserPreferredLanguageRequest: { preferredLanguage: lang }
      });
  };

  useEffect(() => {
    // language preferance should be written to storage both during the initialization
    // and when reacting to language changes in the UI
    const supportedLanguage = supportedLanguages.filter((x) => x.id === preferredLangauge)[0] ?? defaultLanguage;
    i18n.changeLanguage(supportedLanguage.id);
    LanguageStorageService.setPreferredLanguage(supportedLanguage);
  }, [preferredLangauge]);

  return (
    <UserContext.Provider
      value={{
        hasAnyAccessRights,
        isLoading: isLoadingAccessRights || isLoadingPreferredLanguage,
        preferredLangauge,
        changePreferredLanguage
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
