import React, { useEffect } from 'react';
import { AuthStateStorageService, LoadingSpinner } from 'common.ui';
import { useMsal } from 'auth/MsalContext';
import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Page from '../../components/page/Page';

function LoginStatsforvalteren() {
  const { ensureStatsforvalterenLogin, authInProgress } = useMsal();
  const location = useLocation();

  useEffect(() => {
    if (authInProgress) return;

    const parsedQuery = QueryString.parse(location.search);

    if (parsedQuery.redirectUrl !== undefined)
      AuthStateStorageService.setRedirectionState(parsedQuery.redirectUrl as string);

    debugger;
    ensureStatsforvalterenLogin();
  }, [authInProgress, ensureStatsforvalterenLogin, location.search]);

  return (
    <Page header='Log inn som Statsforvalteren'>
      <LoadingSpinner />
    </Page>
  );
}

export default LoginStatsforvalteren;
