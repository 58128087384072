import { SelectOption, useFetch } from 'common.ui';
import { organizationGroupsClient } from 'api/MinSideClients';
import { OrganizationGroupType, OrganizationResponse, OrganizationSearchOrderBy, SortOrder } from 'api/minside';

export type OrganizationsResult = [SelectOption[] | undefined, OrganizationResponse[] | undefined];

export function useOrganizations(
  organizationGroupType?: OrganizationGroupType,
  organizationGroupIds?: number[]
): OrganizationsResult {
  const [fetchedOrganizations] = useFetch<OrganizationsResult>(
    () => fetchAllOrganizations(),
    [undefined, undefined] as OrganizationsResult,
    false,
    [organizationGroupType]
  );

  const fetchAllOrganizations = async (): Promise<OrganizationsResult> => {
    const organizations = await organizationGroupsClient.apiOrganizationGroupsOrganizationsGet({
      pageNumber: 1,
      pageSize: 9999,
      sortOrder: SortOrder.Ascending,
      orderField: OrganizationSearchOrderBy.Name,
      organizationGroupType,
      organizationGroupIds
    });

    if (!organizations.data) {
      return [undefined, undefined] as OrganizationsResult;
    }

    const options = organizations.data.map((d) => {
      return {
        id: d.organizationId?.toString(),
        text: d.name
      } as SelectOption;
    });

    const data = organizations.data || [];

    return [options, data];
  };

  return fetchedOrganizations;
}
