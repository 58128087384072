import Page from 'components/page/Page';
import { t } from 'i18next';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useOrganizations } from 'api/hooks/useOrganizations';
import './ApplicationList.scss';
import { Button, SearchableSingleSelect } from 'common.ui';

type Props = {
  organizationGroupIds: number[] | null | undefined;
  onSelect: (orgId: string) => void;
};

export default function OrganizationList({ organizationGroupIds, onSelect }: Props) {
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [organizationOptions] = useOrganizations(undefined, organizationGroupIds!);

  return (
    <Page header={t('access.requestAccess.verifyOrganizationGroup.title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={12} xl={8}>
            <p>
              <b>{t('access.requestAccess.verifyOrganizationGroup.text')}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={12} xl={8}>
            {/* <Dropdown
              id='selected_organization'
              options={(organizationOptions as SelectOption[]) ?? []}
              onChange={(e) => {
                setSelectedOrganization(e.target.value);
              }}
              labelText={t('access.requestAccess.verifyOrganizationGroup.dropDownLabel')}
            /> */}
            <SearchableSingleSelect
              id='selected_organization'
              selectedId={selectedOrganization}
              label={t('access.requestAccess.fulfilAccessRequestPage.form.lblOrganizationId')}
              options={organizationOptions || []}
              onChange={(val) => {
                onSelect(val);
              }}
            />
          </Col>
        </Row>
        <div className='verify-organization'>
          <Button
            text={t('access.requestAccess.fulfilAccessRequestPage.form.nextButtonText')}
            onClick={() => onSelect(selectedOrganization)}
          />
        </div>
      </Container>
    </Page>
  );
}
