/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PageModel,
    PageModelFromJSON,
    PageModelFromJSONTyped,
    PageModelToJSON,
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseFromJSONTyped,
    PermissionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionResponsePagedResult
 */
export interface PermissionResponsePagedResult {
    /**
     * 
     * @type {PageModel}
     * @memberof PermissionResponsePagedResult
     */
    pageInfo?: PageModel;
    /**
     * 
     * @type {Array<PermissionResponse>}
     * @memberof PermissionResponsePagedResult
     */
    data?: Array<PermissionResponse> | null;
}

export function PermissionResponsePagedResultFromJSON(json: any): PermissionResponsePagedResult {
    return PermissionResponsePagedResultFromJSONTyped(json, false);
}

export function PermissionResponsePagedResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionResponsePagedResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageInfo': !exists(json, 'pageInfo') ? undefined : PageModelFromJSON(json['pageInfo']),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(PermissionResponseFromJSON)),
    };
}

export function PermissionResponsePagedResultToJSON(value?: PermissionResponsePagedResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageInfo': PageModelToJSON(value.pageInfo),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(PermissionResponseToJSON)),
    };
}


