import { permissionsClient } from 'api/MinSideClients';
import { PermissionResponse, PermissionSearchOrderBy, SortOrder } from 'api/minside';
import { SelectOption, useFetch } from 'common.ui';

type PermissionsResult = [SelectOption[] | undefined, PermissionResponse[] | undefined];

export function usePermissions(
  applicationId?: string | null,
  searchAllApplicationsIfNull?: boolean | null
): PermissionsResult {
  const [fetchedPermissions] = useFetch<PermissionsResult>(
    () => fetchAllPermissions(),
    [undefined, undefined] as PermissionsResult,
    false,
    [applicationId]
  );

  const fetchAllPermissions = async (): Promise<PermissionsResult> => {
    if (!applicationId && searchAllApplicationsIfNull === false) {
      return [undefined, undefined] as PermissionsResult;
    }

    const permissions = await permissionsClient.apiPermissionsGet({
      pageNumber: 1,
      pageSize: 9999,
      sortOrder: SortOrder.Ascending,
      orderField: PermissionSearchOrderBy.Name,
      applicationId: applicationId ?? undefined
    });

    if (!permissions.data) {
      return [undefined, undefined] as PermissionsResult;
    }

    const options = permissions.data
      .map((d) => {
        return {
          id: d.permissionId?.toString(),
          text: d.name
        } as SelectOption;
      })
      .sort((o1, o2) => (o1.text || '').localeCompare(o2.text || ''));

    const data = permissions.data || [];

    return [options, data];
  };

  return fetchedPermissions;
}
