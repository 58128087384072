/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationSearchOrderBy {
    Code = 'Code',
    Name = 'Name'
}

export function OrganizationSearchOrderByFromJSON(json: any): OrganizationSearchOrderBy {
    return OrganizationSearchOrderByFromJSONTyped(json, false);
}

export function OrganizationSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationSearchOrderBy {
    return json as OrganizationSearchOrderBy;
}

export function OrganizationSearchOrderByToJSON(value?: OrganizationSearchOrderBy | null): any {
    return value as any;
}

