import React from 'react';
import { LoadingSpinner, useFetch } from 'common.ui';
import { cmsClient } from 'api/MinSideClients';
import { useHistory } from 'react-router-dom';

import FaqList from 'components/faq/FaqList';
import Page from 'components/page/Page';
import { ArticleListItemDto } from 'api/minside';
import RoutePaths from 'RoutePaths';
import { useUser } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';

function Faq() {
  const { preferredLangauge } = useUser();
  const { t } = useTranslation('pages', { keyPrefix: 'help.faq' });
  const history = useHistory();

  const [articles, isLoading] = useFetch<ArticleListItemDto[]>(
    () => fetchArticles(),
    [] as ArticleListItemDto[],
    false,
    [preferredLangauge]
  );

  const fetchArticles = async (): Promise<ArticleListItemDto[]> => {
    return cmsClient.apiCmsArticlesGet();
  };

  return (
    <Page header={t('title')}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FaqList introText={t('text')} articles={articles} onSelect={(a) => history.push(RoutePaths.help + a.url)} />
      )}
    </Page>
  );
}

export default Faq;
