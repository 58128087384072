import RoutePaths from 'RoutePaths';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'common.ui';
import { useTranslation } from 'react-i18next';
import Page from 'components/page/Page';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NoAccessPage() {
  const { t } = useTranslation('pages', { keyPrefix: 'noAccess' });

  return (
    <Page header={t('title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            <p>{t('disclaimer')}</p>
          </Col>
        </Row>

        <Link to={RoutePaths.profileConfigureEmployer}>
          <Button text={t('btLabel')} />
        </Link>
      </Container>
    </Page>
  );
}

export default NoAccessPage;
