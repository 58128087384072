import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './UserPropertyGroup.scss';

export interface IProps {
  label: string;
}

export function UserPropertyGroup({ label }: IProps) {
  return (
    <Row>
      <Col lg='12'>
        <h3 className='user-property-group'>{label}</h3>
      </Col>
    </Row>
  );
}
