/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeEmployerRequest,
    ChangeEmployerRequestFromJSON,
    ChangeEmployerRequestToJSON,
    EmployerDependentAccessesResponse,
    EmployerDependentAccessesResponseFromJSON,
    EmployerDependentAccessesResponseToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ApiExternalUserEmployerPostRequest {
    changeEmployerRequest?: ChangeEmployerRequest;
}

/**
 * 
 */
export class ExternalUserEmployerApi extends runtime.BaseAPI {

    /**
     */
    async apiExternalUserEmployerGetRaw(): Promise<runtime.ApiResponse<EmployerDependentAccessesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-user-employer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployerDependentAccessesResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalUserEmployerGet(): Promise<EmployerDependentAccessesResponse> {
        const response = await this.apiExternalUserEmployerGetRaw();
        return await response.value();
    }

    /**
     */
    async apiExternalUserEmployerPostRaw(requestParameters: ApiExternalUserEmployerPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external-user-employer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeEmployerRequestToJSON(requestParameters.changeEmployerRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExternalUserEmployerPost(requestParameters: ApiExternalUserEmployerPostRequest): Promise<void> {
        await this.apiExternalUserEmployerPostRaw(requestParameters);
    }

}
