/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteUserVerificationRequest,
    DeleteUserVerificationRequestFromJSON,
    DeleteUserVerificationRequestToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    IsUserIdPortenValidatedResponse,
    IsUserIdPortenValidatedResponseFromJSON,
    IsUserIdPortenValidatedResponseToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    UpdateUserPreferredLanguageRequest,
    UpdateUserPreferredLanguageRequestFromJSON,
    UpdateUserPreferredLanguageRequestToJSON,
    UserAccessResponse,
    UserAccessResponseFromJSON,
    UserAccessResponseToJSON,
    UserEventResponse,
    UserEventResponseFromJSON,
    UserEventResponseToJSON,
    UserPreferredLanguageResponse,
    UserPreferredLanguageResponseFromJSON,
    UserPreferredLanguageResponseToJSON,
    UserProfileResponse,
    UserProfileResponseFromJSON,
    UserProfileResponseToJSON,
    VerifiedUserResponse,
    VerifiedUserResponseFromJSON,
    VerifiedUserResponseToJSON,
    VerifyUserRequest,
    VerifyUserRequestFromJSON,
    VerifyUserRequestToJSON,
    VerifyUserResponse,
    VerifyUserResponseFromJSON,
    VerifyUserResponseToJSON,
} from '../models';

export interface ApiProfileCurrentPreferredLanguagePutRequest {
    updateUserPreferredLanguageRequest?: UpdateUserPreferredLanguageRequest;
}

export interface ApiProfilePermissionsPermissionIdDeleteRequest {
    permissionId: number;
}

export interface ApiProfilePermissionsPermissionIdOrganizationOrganizationIdDeleteRequest {
    permissionId: number;
    organizationId: number;
}

export interface ApiProfileRolesRoleIdDeleteRequest {
    roleId: number;
}

export interface ApiProfileRolesRoleIdOrganizationOrganizationIdDeleteRequest {
    roleId: number;
    organizationId: number;
}

export interface ApiProfileUserVerificationDeleteRequest {
    deleteUserVerificationRequest?: DeleteUserVerificationRequest;
}

export interface ApiProfileUserVerificationPostRequest {
    verifyUserRequest?: VerifyUserRequest;
}

export interface ApiProfileUserVerificationReplaceableVerifiedUsersSearchesPostRequest {
    verifyUserRequest?: VerifyUserRequest;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     */
    async apiProfileConsentsGetRaw(): Promise<runtime.ApiResponse<Array<UserEventResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/consents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserEventResponseFromJSON));
    }

    /**
     */
    async apiProfileConsentsGet(): Promise<Array<UserEventResponse>> {
        const response = await this.apiProfileConsentsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiProfileCurrentGetRaw(): Promise<runtime.ApiResponse<UserProfileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfileCurrentGet(): Promise<UserProfileResponse> {
        const response = await this.apiProfileCurrentGetRaw();
        return await response.value();
    }

    /**
     */
    async apiProfileCurrentPermissionsGetRaw(): Promise<runtime.ApiResponse<Array<UserAccessResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/current-permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserAccessResponseFromJSON));
    }

    /**
     */
    async apiProfileCurrentPermissionsGet(): Promise<Array<UserAccessResponse>> {
        const response = await this.apiProfileCurrentPermissionsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiProfileCurrentPreferredLanguageGetRaw(): Promise<runtime.ApiResponse<UserPreferredLanguageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/current/preferred-language`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferredLanguageResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfileCurrentPreferredLanguageGet(): Promise<UserPreferredLanguageResponse> {
        const response = await this.apiProfileCurrentPreferredLanguageGetRaw();
        return await response.value();
    }

    /**
     */
    async apiProfileCurrentPreferredLanguagePutRaw(requestParameters: ApiProfileCurrentPreferredLanguagePutRequest): Promise<runtime.ApiResponse<UserPreferredLanguageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/profile/current/preferred-language`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPreferredLanguageRequestToJSON(requestParameters.updateUserPreferredLanguageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferredLanguageResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfileCurrentPreferredLanguagePut(requestParameters: ApiProfileCurrentPreferredLanguagePutRequest): Promise<UserPreferredLanguageResponse> {
        const response = await this.apiProfileCurrentPreferredLanguagePutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProfileEventsGetRaw(): Promise<runtime.ApiResponse<Array<UserEventResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserEventResponseFromJSON));
    }

    /**
     */
    async apiProfileEventsGet(): Promise<Array<UserEventResponse>> {
        const response = await this.apiProfileEventsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiProfilePermissionsPermissionIdDeleteRaw(requestParameters: ApiProfilePermissionsPermissionIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling apiProfilePermissionsPermissionIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/permissions/{permissionId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfilePermissionsPermissionIdDelete(requestParameters: ApiProfilePermissionsPermissionIdDeleteRequest): Promise<void> {
        await this.apiProfilePermissionsPermissionIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiProfilePermissionsPermissionIdOrganizationOrganizationIdDeleteRaw(requestParameters: ApiProfilePermissionsPermissionIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling apiProfilePermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling apiProfilePermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/permissions/{permissionId}/organization/{organizationId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfilePermissionsPermissionIdOrganizationOrganizationIdDelete(requestParameters: ApiProfilePermissionsPermissionIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.apiProfilePermissionsPermissionIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiProfileRolesRoleIdDeleteRaw(requestParameters: ApiProfileRolesRoleIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling apiProfileRolesRoleIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfileRolesRoleIdDelete(requestParameters: ApiProfileRolesRoleIdDeleteRequest): Promise<void> {
        await this.apiProfileRolesRoleIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiProfileRolesRoleIdOrganizationOrganizationIdDeleteRaw(requestParameters: ApiProfileRolesRoleIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling apiProfileRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling apiProfileRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/roles/{roleId}/organization/{organizationId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfileRolesRoleIdOrganizationOrganizationIdDelete(requestParameters: ApiProfileRolesRoleIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.apiProfileRolesRoleIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiProfileUserVerificationDeleteRaw(requestParameters: ApiProfileUserVerificationDeleteRequest): Promise<runtime.ApiResponse<VerifyUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/profile/user-verification`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteUserVerificationRequestToJSON(requestParameters.deleteUserVerificationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfileUserVerificationDelete(requestParameters: ApiProfileUserVerificationDeleteRequest): Promise<VerifyUserResponse> {
        const response = await this.apiProfileUserVerificationDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProfileUserVerificationGetRaw(): Promise<runtime.ApiResponse<IsUserIdPortenValidatedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profile/user-verification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IsUserIdPortenValidatedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfileUserVerificationGet(): Promise<IsUserIdPortenValidatedResponse> {
        const response = await this.apiProfileUserVerificationGetRaw();
        return await response.value();
    }

    /**
     */
    async apiProfileUserVerificationPostRaw(requestParameters: ApiProfileUserVerificationPostRequest): Promise<runtime.ApiResponse<VerifyUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/profile/user-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyUserRequestToJSON(requestParameters.verifyUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfileUserVerificationPost(requestParameters: ApiProfileUserVerificationPostRequest): Promise<VerifyUserResponse> {
        const response = await this.apiProfileUserVerificationPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProfileUserVerificationReplaceableVerifiedUsersSearchesPostRaw(requestParameters: ApiProfileUserVerificationReplaceableVerifiedUsersSearchesPostRequest): Promise<runtime.ApiResponse<Array<VerifiedUserResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/profile/user-verification/replaceable-verified-users-searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyUserRequestToJSON(requestParameters.verifyUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VerifiedUserResponseFromJSON));
    }

    /**
     */
    async apiProfileUserVerificationReplaceableVerifiedUsersSearchesPost(requestParameters: ApiProfileUserVerificationReplaceableVerifiedUsersSearchesPostRequest): Promise<Array<VerifiedUserResponse>> {
        const response = await this.apiProfileUserVerificationReplaceableVerifiedUsersSearchesPostRaw(requestParameters);
        return await response.value();
    }

}
