import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Button } from 'common.ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserIdentityOrigin, UserProfileResponse } from 'api/minside';
import { UserData } from 'components/profile/UserData';
import AAData from 'components/profile/AAData';
import KrrInfo from 'components/profile/KrrInfo';
// import AltinnRoles from 'components/profile/AltinnRoles';
import { useMsal } from 'auth/MsalContext';

export type IProps = {
  user: UserProfileResponse;
};

function ExternalUserInfo({ user }: IProps) {
  const { resetPassword } = useMsal();
  const { t } = useTranslation('pages');

  return (
    <Container fluid>
      <UserData user={user} />

      {/* only local account B2C users can change their password (through B2C), while other users must use their IdP. */}
      {user.identityOrigin === UserIdentityOrigin.Local && (
        <Row>
          <Col>
            <Button
              id='btnChangePassword'
              text={t('externalUserInfo.changePasswordButtonText')}
              onClick={resetPassword}
            />
          </Col>
        </Row>
      )}

      {user.isValidated && (
        <>
          {user.userKrrInfo && <KrrInfo user={user.userKrrInfo} />}
          <AAData user={user} />
        </>
      )}

      {/* {user.altinnRoles && user.altinnRoles.length > 0 && <AltinnRoles roles={user.altinnRoles ?? []} />} */}
    </Container>
  );
}

export default ExternalUserInfo;
