/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessRequestRequest,
    AccessRequestRequestFromJSON,
    AccessRequestRequestToJSON,
    AccessRequestResponse,
    AccessRequestResponseFromJSON,
    AccessRequestResponseToJSON,
    AccessRequestResponsePagedResult,
    AccessRequestResponsePagedResultFromJSON,
    AccessRequestResponsePagedResultToJSON,
    AccessRequestSearchOrderBy,
    AccessRequestSearchOrderByFromJSON,
    AccessRequestSearchOrderByToJSON,
    AccessRequestUpdateStatusNotificationPreviewResponse,
    AccessRequestUpdateStatusNotificationPreviewResponseFromJSON,
    AccessRequestUpdateStatusNotificationPreviewResponseToJSON,
    AccessRequestUpdateStatusRequest,
    AccessRequestUpdateStatusRequestFromJSON,
    AccessRequestUpdateStatusRequestToJSON,
    AccessRequestUpdateStatusResponse,
    AccessRequestUpdateStatusResponseFromJSON,
    AccessRequestUpdateStatusResponseToJSON,
    ApprovedAccessOrderBy,
    ApprovedAccessOrderByFromJSON,
    ApprovedAccessOrderByToJSON,
    ApprovedAccessResponsePagedResult,
    ApprovedAccessResponsePagedResultFromJSON,
    ApprovedAccessResponsePagedResultToJSON,
    ForbidResult,
    ForbidResultFromJSON,
    ForbidResultToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiAccessRequestsApprovedGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: ApprovedAccessOrderBy;
}

export interface ApiAccessRequestsAssignedUsersGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: ApprovedAccessOrderBy;
}

export interface ApiAccessRequestsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: AccessRequestSearchOrderBy;
}

export interface ApiAccessRequestsPostRequest {
    accessRequestRequest?: AccessRequestRequest;
}

export interface ApiAccessRequestsUuidStatusPreviewPostRequest {
    uuid: string;
    accessRequestUpdateStatusRequest?: AccessRequestUpdateStatusRequest;
}

export interface ApiAccessRequestsUuidStatusPutRequest {
    uuid: string;
    accessRequestUpdateStatusRequest?: AccessRequestUpdateStatusRequest;
}

export interface GetAccessRequestByUuidRequest {
    uuid: string;
}

/**
 * 
 */
export class AccessRequestsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccessRequestsApprovedGetRaw(requestParameters: ApiAccessRequestsApprovedGetRequest): Promise<runtime.ApiResponse<ApprovedAccessResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/access-requests/approved`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprovedAccessResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsApprovedGet(requestParameters: ApiAccessRequestsApprovedGetRequest): Promise<ApprovedAccessResponsePagedResult> {
        const response = await this.apiAccessRequestsApprovedGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsAssignedUsersGetRaw(requestParameters: ApiAccessRequestsAssignedUsersGetRequest): Promise<runtime.ApiResponse<ApprovedAccessResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/access-requests/assigned-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprovedAccessResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsAssignedUsersGet(requestParameters: ApiAccessRequestsAssignedUsersGetRequest): Promise<ApprovedAccessResponsePagedResult> {
        const response = await this.apiAccessRequestsAssignedUsersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsGetRaw(requestParameters: ApiAccessRequestsGetRequest): Promise<runtime.ApiResponse<AccessRequestResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/access-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsGet(requestParameters: ApiAccessRequestsGetRequest): Promise<AccessRequestResponsePagedResult> {
        const response = await this.apiAccessRequestsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsPostRaw(requestParameters: ApiAccessRequestsPostRequest): Promise<runtime.ApiResponse<AccessRequestResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestRequestToJSON(requestParameters.accessRequestRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsPost(requestParameters: ApiAccessRequestsPostRequest): Promise<AccessRequestResponse> {
        const response = await this.apiAccessRequestsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsUuidStatusPreviewPostRaw(requestParameters: ApiAccessRequestsUuidStatusPreviewPostRequest): Promise<runtime.ApiResponse<AccessRequestUpdateStatusNotificationPreviewResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiAccessRequestsUuidStatusPreviewPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/{uuid}/status/preview`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestUpdateStatusRequestToJSON(requestParameters.accessRequestUpdateStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestUpdateStatusNotificationPreviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsUuidStatusPreviewPost(requestParameters: ApiAccessRequestsUuidStatusPreviewPostRequest): Promise<AccessRequestUpdateStatusNotificationPreviewResponse> {
        const response = await this.apiAccessRequestsUuidStatusPreviewPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsUuidStatusPutRaw(requestParameters: ApiAccessRequestsUuidStatusPutRequest): Promise<runtime.ApiResponse<AccessRequestUpdateStatusResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiAccessRequestsUuidStatusPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/{uuid}/status`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestUpdateStatusRequestToJSON(requestParameters.accessRequestUpdateStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestUpdateStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsUuidStatusPut(requestParameters: ApiAccessRequestsUuidStatusPutRequest): Promise<AccessRequestUpdateStatusResponse> {
        const response = await this.apiAccessRequestsUuidStatusPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAccessRequestByUuidRaw(requestParameters: GetAccessRequestByUuidRequest): Promise<runtime.ApiResponse<AccessRequestResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getAccessRequestByUuid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/access-requests/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAccessRequestByUuid(requestParameters: GetAccessRequestByUuidRequest): Promise<AccessRequestResponse> {
        const response = await this.getAccessRequestByUuidRaw(requestParameters);
        return await response.value();
    }

}
