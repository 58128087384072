import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Language, UserKrrInfoResponse } from 'api/minside';
import { toYesNo } from 'helpers/booleanHelpers';
import { useUser } from 'contexts/UserContext';
import { UserPropertyGroup } from './UserPropertyGroup';
import { UserProperty } from './UserProperty';

import './KrrInfo.scss';

export type IProps = {
  user: UserKrrInfoResponse;
};

function KrrInfo({ user }: IProps) {
  const { t } = useTranslation('components');
  const { preferredLangauge } = useUser();
  const krrUrl =
    preferredLangauge === Language.Norwegian
      ? 'https://eid.difi.no/nb/kontakt-og-reservasjonsregisteret'
      : 'https://eid.difi.no/en/common-contact-register';

  return (
    <Row>
      <Col lg='8'>
        <UserPropertyGroup label={t('profile.krrInfo.title')} />
        <UserProperty
          label={t('profile.krrInfo.telephoneLabel')}
          value={user.mobileNr}
          note={
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id='popover-basic'>
                  <Popover.Content>
                    <Trans t={t} i18nKey='profile.krrInfo.popoverContent'>
                      Hvis du ser at dine data i kontakt- og reservasjonregisteret er feil kan du
                      <a href={krrUrl}>endre opplysningene</a>
                    </Trans>
                  </Popover.Content>
                </Popover>
              }
            >
              <button type='button' className='btn-popover-trigger'>
                {t('profile.krrInfo.popoverTrigger')}
              </button>
            </OverlayTrigger>
          }
        />
        <UserProperty label={t('profile.krrInfo.emailLabel')} value={user.email} />
        <UserProperty
          label={t('profile.krrInfo.reservedLabel')}
          value={toYesNo(user.isReserved ?? false, t('profile.krrInfo.reservedYes'), t('profile.krrInfo.reservedNo'))}
        />
      </Col>
    </Row>
  );
}

export default KrrInfo;
