/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMigrationQueryRequest
 */
export interface UserMigrationQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryRequest
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryRequest
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryRequest
     */
    password?: string | null;
}

export function UserMigrationQueryRequestFromJSON(json: any): UserMigrationQueryRequest {
    return UserMigrationQueryRequestFromJSONTyped(json, false);
}

export function UserMigrationQueryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationQueryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function UserMigrationQueryRequestToJSON(value?: UserMigrationQueryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'username': value.username,
        'password': value.password,
    };
}


