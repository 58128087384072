/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    requireIdPorten?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    requiresMfa?: boolean;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof ApplicationResponse
     */
    status?: ApplicationStatus;
}

export function ApplicationResponseFromJSON(json: any): ApplicationResponse {
    return ApplicationResponseFromJSONTyped(json, false);
}

export function ApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'requireIdPorten': !exists(json, 'requireIdPorten') ? undefined : json['requireIdPorten'],
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
        'status': !exists(json, 'status') ? undefined : ApplicationStatusFromJSON(json['status']),
    };
}

export function ApplicationResponseToJSON(value?: ApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'requireIdPorten': value.requireIdPorten,
        'requiresMfa': value.requiresMfa,
        'status': ApplicationStatusToJSON(value.status),
    };
}


