import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserProfileResponse } from 'api/minside';
import { UserPropertyGroup } from './UserPropertyGroup';

type IProps = {
  user: UserProfileResponse;
};

function AAData({ user }: IProps) {
  return (
    <></>
    // <Row>
    //   <Col lg='8'>
    //     <UserPropertyGroup label='Arbeidsgiver (AA-registeret)' />

    //     <Row>
    //       <Col lg='3'>TBD</Col>
    //     </Row>
    //   </Col>
    // </Row>
  );
}

export default AAData;
