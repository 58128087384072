/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
    SapUserDataResponse,
    SapUserDataResponseFromJSON,
    SapUserDataResponseFromJSONTyped,
    SapUserDataResponseToJSON,
    UserIdentityOrigin,
    UserIdentityOriginFromJSON,
    UserIdentityOriginFromJSONTyped,
    UserIdentityOriginToJSON,
    UserKrrInfoResponse,
    UserKrrInfoResponseFromJSON,
    UserKrrInfoResponseFromJSONTyped,
    UserKrrInfoResponseToJSON,
    UserOrganizationGroupResponse,
    UserOrganizationGroupResponseFromJSON,
    UserOrganizationGroupResponseFromJSONTyped,
    UserOrganizationGroupResponseToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    telefonNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    mfaPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    employer?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof UserProfileResponse
     */
    userType?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponse
     */
    isValidated?: boolean;
    /**
     * 
     * @type {UserIdentityOrigin}
     * @memberof UserProfileResponse
     */
    identityOrigin?: UserIdentityOrigin;
    /**
     * 
     * @type {Language}
     * @memberof UserProfileResponse
     */
    preferredLanguage?: Language;
    /**
     * 
     * @type {SapUserDataResponse}
     * @memberof UserProfileResponse
     */
    sapUserData?: SapUserDataResponse;
    /**
     * 
     * @type {UserKrrInfoResponse}
     * @memberof UserProfileResponse
     */
    userKrrInfo?: UserKrrInfoResponse;
    /**
     * 
     * @type {UserOrganizationGroupResponse}
     * @memberof UserProfileResponse
     */
    statsforvalterenOrganizationGroup?: UserOrganizationGroupResponse;
}

export function UserProfileResponseFromJSON(json: any): UserProfileResponse {
    return UserProfileResponseFromJSONTyped(json, false);
}

export function UserProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'telefonNr': !exists(json, 'telefonNr') ? undefined : json['telefonNr'],
        'mfaPhoneNumber': !exists(json, 'mfaPhoneNumber') ? undefined : json['mfaPhoneNumber'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'isValidated': !exists(json, 'isValidated') ? undefined : json['isValidated'],
        'identityOrigin': !exists(json, 'identityOrigin') ? undefined : UserIdentityOriginFromJSON(json['identityOrigin']),
        'preferredLanguage': !exists(json, 'preferredLanguage') ? undefined : LanguageFromJSON(json['preferredLanguage']),
        'sapUserData': !exists(json, 'sapUserData') ? undefined : SapUserDataResponseFromJSON(json['sapUserData']),
        'userKrrInfo': !exists(json, 'userKrrInfo') ? undefined : UserKrrInfoResponseFromJSON(json['userKrrInfo']),
        'statsforvalterenOrganizationGroup': !exists(json, 'statsforvalterenOrganizationGroup') ? undefined : UserOrganizationGroupResponseFromJSON(json['statsforvalterenOrganizationGroup']),
    };
}

export function UserProfileResponseToJSON(value?: UserProfileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'email': value.email,
        'name': value.name,
        'telefonNr': value.telefonNr,
        'mfaPhoneNumber': value.mfaPhoneNumber,
        'employer': value.employer,
        'userType': UserTypeToJSON(value.userType),
        'isValidated': value.isValidated,
        'identityOrigin': UserIdentityOriginToJSON(value.identityOrigin),
        'preferredLanguage': LanguageToJSON(value.preferredLanguage),
        'sapUserData': SapUserDataResponseToJSON(value.sapUserData),
        'userKrrInfo': UserKrrInfoResponseToJSON(value.userKrrInfo),
        'statsforvalterenOrganizationGroup': UserOrganizationGroupResponseToJSON(value.statsforvalterenOrganizationGroup),
    };
}


