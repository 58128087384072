import { AccessRequestResponse } from 'api/minside';
import { accessRequestClient } from 'api/MinSideClients';
import { useFetch } from 'common.ui';
import { DependencyList } from 'react';

export function useAccessRequest(
  accessRequestUuid: string | undefined,
  dependencies: DependencyList = [] as DependencyList
): [AccessRequestResponse | undefined] {
  const [fetchedAccessRequest] = useFetch<AccessRequestResponse | undefined>(
    () => fetchAccessRequest(),
    undefined,
    false,
    [accessRequestUuid, ...dependencies]
  );

  const fetchAccessRequest = async (): Promise<AccessRequestResponse | undefined> => {
    if (!accessRequestUuid) return undefined;
    const result = await accessRequestClient.getAccessRequestByUuid({
      uuid: accessRequestUuid
    });
    return result;
  };

  return [fetchedAccessRequest];
}
