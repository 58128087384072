/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationSearchOrderBy {
    Name = 'Name',
    Description = 'Description'
}

export function ApplicationSearchOrderByFromJSON(json: any): ApplicationSearchOrderBy {
    return ApplicationSearchOrderByFromJSONTyped(json, false);
}

export function ApplicationSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSearchOrderBy {
    return json as ApplicationSearchOrderBy;
}

export function ApplicationSearchOrderByToJSON(value?: ApplicationSearchOrderBy | null): any {
    return value as any;
}

