import React, { useEffect } from 'react';
import { AuthStateStorageService, LoadingSpinner } from 'common.ui';
import { useMsal } from 'auth/MsalContext';
import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Page from '../../components/page/Page';

function LoginInternal() {
  const { ensureInternalUserLogin, authInProgress } = useMsal();
  const location = useLocation();

  useEffect(() => {
    if (authInProgress) return;

    const parsedHash = QueryString.parse(location.search);

    if (parsedHash.redirectUrl) AuthStateStorageService.setRedirectionState(parsedHash.redirectUrl as string);

    ensureInternalUserLogin();
  }, [authInProgress, ensureInternalUserLogin, location.search]);

  return (
    <Page header='Log inn som intern ansatte'>
      <LoadingSpinner />
    </Page>
  );
}

export default LoginInternal;
