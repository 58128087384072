import RoutePaths from 'RoutePaths';

const errorHandlers: ((msg: string) => void)[] = [];
const maintenanceHandlers: (() => void)[] = [];

export function RegisterErrorCallback(callback: (msg: string) => void) {
  errorHandlers.push(callback);
}

export function RegisterMaintenanceCallback(callback: () => void) {
  maintenanceHandlers.push(callback);
}

export function RegisterError(msg: string) {
  for (const cb of errorHandlers) {
    cb(msg);
  }
}

export function RegisterMaintenance() {
  for (const cb of maintenanceHandlers) {
    cb();
  }
}
