/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalEndUserResponse
 */
export interface ExternalEndUserResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalEndUserResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEndUserResponse
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEndUserResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEndUserResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEndUserResponse
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEndUserResponse
     */
    mfaPhoneNumber?: string | null;
}

export function ExternalEndUserResponseFromJSON(json: any): ExternalEndUserResponse {
    return ExternalEndUserResponseFromJSONTyped(json, false);
}

export function ExternalEndUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEndUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'mfaPhoneNumber': !exists(json, 'mfaPhoneNumber') ? undefined : json['mfaPhoneNumber'],
    };
}

export function ExternalEndUserResponseToJSON(value?: ExternalEndUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'displayName': value.displayName,
        'email': value.email,
        'firstName': value.firstName,
        'surname': value.surname,
        'mfaPhoneNumber': value.mfaPhoneNumber,
    };
}


