import React, { useEffect, useState } from 'react';
import { Button, LoadingSpinner } from 'common.ui';
import { AuthService } from 'auth/AuthService';
import { useHistory, useLocation } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useMsal } from '../../auth/MsalContext';
import { B2cPolicies } from '../../auth/AzureB2cConfig';
import Page from '../../components/page/Page';

function PasswordReset() {
  const { setB2cPolicy, resetPassword } = useMsal();
  const { t } = useTranslation('pages', { keyPrefix: 'passwordReset.passwordReset' });
  const [isPasswordResetFlowCancelled, setIsPasswordResetFlowCancelled] = useState<boolean>(false);
  const [isPasswordResetFlowSuccessful, setIsPasswordResetFlowSuccessful] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const parsedQueryString = QueryString.parse(location.search);
    const parsedHashString = QueryString.parse(location.hash);
    const b2cErrorCode = 'AADB2C90091';

    if (parsedQueryString.autoForward) {
      resetPassword(parsedQueryString.ui_locales as string | undefined);
      return;
    }

    if (!parsedQueryString.error && !parsedHashString.error) {
      setIsPasswordResetFlowSuccessful(true);
    } else if (
      (parsedQueryString.error && parsedQueryString.error_description?.indexOf(b2cErrorCode) !== -1) ||
      (parsedHashString.error && parsedHashString.error_description?.indexOf(b2cErrorCode) !== -1)
    ) {
      setIsPasswordResetFlowCancelled(true);
    } else if (parsedQueryString.error || parsedHashString.error) {
      // eslint-disable-next-line no-console
      console.log(`Password reset error: ${parsedQueryString.error || parsedHashString.error}`);
      history.push(RoutePaths.home);
    }
  }, [history, location.hash, location.search, resetPassword]);

  const message = !isPasswordResetFlowCancelled ? t('message.completed') : t('message.cancelled');

  const onButtonClick = () => {
    AuthService.clearStorageValues();
    if (isPasswordResetFlowCancelled) {
      resetPassword();
    } else {
      setB2cPolicy(B2cPolicies.names.externalUser);
      history.push(RoutePaths.home);
    }
  };

  return (
    <Page header={t('title')}>
      {isPasswordResetFlowSuccessful || isPasswordResetFlowCancelled ? (
        <p>
          <br />
          {message}
          <br />
          <br />
          <Button
            type='button'
            onClick={onButtonClick}
            text={isPasswordResetFlowCancelled ? t('btnText.changePassword') : t('btnText.continue')}
            styleType='light'
          />
        </p>
      ) : (
        <LoadingSpinner />
      )}
    </Page>
  );
}

export default PasswordReset;
