/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
    UserMigrationQueryApplicationResponse,
    UserMigrationQueryApplicationResponseFromJSON,
    UserMigrationQueryApplicationResponseFromJSONTyped,
    UserMigrationQueryApplicationResponseToJSON,
    UserMigrationQueryPermissionResponse,
    UserMigrationQueryPermissionResponseFromJSON,
    UserMigrationQueryPermissionResponseFromJSONTyped,
    UserMigrationQueryPermissionResponseToJSON,
    UserMigrationQueryRoleResponse,
    UserMigrationQueryRoleResponseFromJSON,
    UserMigrationQueryRoleResponseFromJSONTyped,
    UserMigrationQueryRoleResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMigrationQueryResponse
 */
export interface UserMigrationQueryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserMigrationQueryResponse
     */
    requireUserInput?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryResponse
     */
    username?: string | null;
    /**
     * 
     * @type {UserMigrationQueryApplicationResponse}
     * @memberof UserMigrationQueryResponse
     */
    application?: UserMigrationQueryApplicationResponse;
    /**
     * 
     * @type {UserMigrationQueryRoleResponse}
     * @memberof UserMigrationQueryResponse
     */
    role?: UserMigrationQueryRoleResponse;
    /**
     * 
     * @type {UserMigrationQueryPermissionResponse}
     * @memberof UserMigrationQueryResponse
     */
    permission?: UserMigrationQueryPermissionResponse;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof UserMigrationQueryResponse
     */
    organization?: OrganizationResponse;
}

export function UserMigrationQueryResponseFromJSON(json: any): UserMigrationQueryResponse {
    return UserMigrationQueryResponseFromJSONTyped(json, false);
}

export function UserMigrationQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requireUserInput': !exists(json, 'requireUserInput') ? undefined : json['requireUserInput'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'application': !exists(json, 'application') ? undefined : UserMigrationQueryApplicationResponseFromJSON(json['application']),
        'role': !exists(json, 'role') ? undefined : UserMigrationQueryRoleResponseFromJSON(json['role']),
        'permission': !exists(json, 'permission') ? undefined : UserMigrationQueryPermissionResponseFromJSON(json['permission']),
        'organization': !exists(json, 'organization') ? undefined : OrganizationResponseFromJSON(json['organization']),
    };
}

export function UserMigrationQueryResponseToJSON(value?: UserMigrationQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requireUserInput': value.requireUserInput,
        'username': value.username,
        'application': UserMigrationQueryApplicationResponseToJSON(value.application),
        'role': UserMigrationQueryRoleResponseToJSON(value.role),
        'permission': UserMigrationQueryPermissionResponseToJSON(value.permission),
        'organization': OrganizationResponseToJSON(value.organization),
    };
}


