import { VerifiedUserResponse } from 'api/minside/models';
import { GenericValidationError } from 'common.ui/dist/hooks/form/useBoundForm';
import { Action, VerifiedUsersFoundAction, ValidationErrorAction } from './actions';

export interface ValidationState {
  isValidationSuccessful: boolean;
  userToUnverify: VerifiedUserResponse | undefined;
  validationError: GenericValidationError | undefined;
  isCancelled: boolean;
  isLoading: boolean;
}

export const reducerMap = {
  verifiedUsersFound: 'verified_users_found',
  userSuccessfullyVerified: 'user_successfully_verified',
  validationError: 'validation_error',
  verificationCancelled: 'verification_cancelled',
  loading: 'loading',
  loadingFinished: 'loading_finished'
};

export const reducer = (state: ValidationState, action: Action): ValidationState => {
  switch (action.type) {
    case reducerMap.verifiedUsersFound: {
      const verifiedUsersFound = action as VerifiedUsersFoundAction;
      return {
        ...state,
        userToUnverify: verifiedUsersFound.payload,
        validationError: undefined,
        isLoading: false
      };
    }
    case reducerMap.userSuccessfullyVerified: {
      return {
        ...state,
        isValidationSuccessful: true,
        validationError: undefined,
        isCancelled: false,
        isLoading: false
      };
    }
    case reducerMap.verificationCancelled: {
      return {
        ...state,
        validationError: undefined,
        isCancelled: true,
        isLoading: false
      };
    }
    case reducerMap.validationError: {
      const validationError = action as ValidationErrorAction;
      return {
        ...state,
        validationError: validationError.payload,
        isCancelled: false,
        isLoading: false
      };
    }
    case reducerMap.loading: {
      return { ...state, isLoading: true };
    }
    case reducerMap.loadingFinished: {
      return { ...state, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
