import { SelectOption, useFetch } from 'common.ui';
import { meApiClient } from 'api/MinSideClients';
import { ApplicationResponse } from 'api/minside';

export type ApplicationsResult = [SelectOption[] | undefined, ApplicationResponse[] | undefined];

export function useUserApplications(): ApplicationsResult {
  const [fetchedApplications] = useFetch<ApplicationsResult>(
    () => fetchUserApplications(),
    [undefined, undefined] as ApplicationsResult,
    false,
    []
  );

  const fetchUserApplications = async (): Promise<ApplicationsResult> => {
    const userApplications = await meApiClient.apiMeApplicationsGet();

    if (!userApplications) {
      return [undefined, undefined] as ApplicationsResult;
    }

    const data = userApplications || [];

    const options = data
      .map((app) => {
        return {
          id: app.id,
          text: app.description || app.name
        } as SelectOption;
      })
      .sort((o1, o2) => (o1.text || '').localeCompare(o2.text || ''));

    return [options, data];
  };

  return fetchedApplications;
}
