/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessRequestRequest
 */
export interface AccessRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessRequestRequest
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestRequest
     */
    employer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestRequest
     */
    requestedRoleId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestRequest
     */
    requestedPermissionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestRequest
     */
    organizationId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestRequest
     */
    acceptGdprRequirement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestRequest
     */
    foreignEmployerRequirement?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestRequest
     */
    acceptOrganizationRequirement?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestRequest
     */
    ignoreOrganizationRequirement?: boolean | null;
}

export function AccessRequestRequestFromJSON(json: any): AccessRequestRequest {
    return AccessRequestRequestFromJSONTyped(json, false);
}

export function AccessRequestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'requestedRoleId': !exists(json, 'requestedRoleId') ? undefined : json['requestedRoleId'],
        'requestedPermissionId': !exists(json, 'requestedPermissionId') ? undefined : json['requestedPermissionId'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'acceptGdprRequirement': !exists(json, 'acceptGdprRequirement') ? undefined : json['acceptGdprRequirement'],
        'foreignEmployerRequirement': !exists(json, 'foreignEmployerRequirement') ? undefined : json['foreignEmployerRequirement'],
        'acceptOrganizationRequirement': !exists(json, 'acceptOrganizationRequirement') ? undefined : json['acceptOrganizationRequirement'],
        'ignoreOrganizationRequirement': !exists(json, 'ignoreOrganizationRequirement') ? undefined : json['ignoreOrganizationRequirement'],
    };
}

export function AccessRequestRequestToJSON(value?: AccessRequestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'description': value.description,
        'employer': value.employer,
        'requestedRoleId': value.requestedRoleId,
        'requestedPermissionId': value.requestedPermissionId,
        'organizationId': value.organizationId,
        'acceptGdprRequirement': value.acceptGdprRequirement,
        'foreignEmployerRequirement': value.foreignEmployerRequirement,
        'acceptOrganizationRequirement': value.acceptOrganizationRequirement,
        'ignoreOrganizationRequirement': value.ignoreOrganizationRequirement,
    };
}


