import React, { useEffect, useReducer } from 'react';
import { LoadingSpinner } from 'common.ui';
import { VerifiedUserResponse, VerifyUserResponse } from 'api/minside/models';
import { profileClient } from 'api/MinSideClients';

import { ProfileValidationCancelled } from 'components/profile-validate/ProfileValidationCancelled';
import { ProfileValidationError } from 'components/profile-validate/ProfileValidationError';
import { ProfileValidationSuccessful } from 'components/profile-validate/ProfileValidationSuccessful';
import { ProfileUnvalidate } from 'components/profile-validate/ProfileUnvalidate';

import { reducer, reducerMap } from 'components/profile-validate/reducers';
import { GenericValidationError } from 'common.ui/dist/hooks/form/useBoundForm';
import { useTranslation } from 'react-i18next';
import { ValidationErrorAction, VerifiedUsersFoundAction } from './actions';

interface IProps {
  idPortenAccessToken: string;
  onLogin: () => void;
}

export const ProfileValidationAuthenticatedUser = ({ idPortenAccessToken, onLogin }: IProps) => {
  const { t } = useTranslation('components');
  const [validationState, dispatch] = useReducer(reducer, {
    isCancelled: false,
    isLoading: true,
    isValidationSuccessful: false,
    userToUnverify: undefined,
    validationError: undefined
  });

  const asyncDispatchWrap = async (asyncDispatch: () => Promise<void>) => {
    try {
      dispatch({ type: reducerMap.loading });
      await asyncDispatch();
    } catch (e) {
      if (e instanceof Response && e?.status === 400) {
        const error = (await e.json()) as GenericValidationError;
        if (error)
          dispatch({
            type: reducerMap.validationError,
            payload: error
          } as ValidationErrorAction);
      }
    }
  };

  const unverifyUser = async (accessToken: string, removeFromUserId: string): Promise<void> => {
    await profileClient.apiProfileUserVerificationDelete({
      deleteUserVerificationRequest: {
        accessToken,
        removeFromUserId
      }
    });
  };

  const verifyUser = async (accessToken: string): Promise<VerifyUserResponse | undefined> => {
    const result = await profileClient.apiProfileUserVerificationPost({
      verifyUserRequest: {
        accessToken
      }
    });
    return result;
  };

  const fetchUsersToUnverify = async (accessToken: string): Promise<VerifiedUserResponse[]> => {
    return profileClient.apiProfileUserVerificationReplaceableVerifiedUsersSearchesPost({
      verifyUserRequest: {
        accessToken
      }
    });
  };

  const onUnverify = async (): Promise<void> => {
    await asyncDispatchWrap(async () => {
      await unverifyUser(idPortenAccessToken, validationState.userToUnverify?.userId ?? '');
      await verifyUser(idPortenAccessToken);
      dispatch({ type: reducerMap.userSuccessfullyVerified });
    });
  };

  useEffect(() => {
    if (idPortenAccessToken)
      asyncDispatchWrap(async () => {
        const usersToUnverify = await fetchUsersToUnverify(idPortenAccessToken as string);
        if (usersToUnverify !== undefined && usersToUnverify.length > 0) {
          dispatch({
            type: reducerMap.verifiedUsersFound,
            payload: usersToUnverify[0]
          } as VerifiedUsersFoundAction);
        } else {
          await verifyUser(idPortenAccessToken as string);
          dispatch({ type: reducerMap.userSuccessfullyVerified });
        }
      });
  }, [idPortenAccessToken]);

  if (validationState.isLoading) return <LoadingSpinner />;

  if (validationState.isCancelled) return <ProfileValidationCancelled onLogin={onLogin} />;

  if (validationState.validationError)
    return <ProfileValidationError validationError={validationState.validationError} onLogin={onLogin} />;

  if (validationState.isValidationSuccessful) {
    return <ProfileValidationSuccessful unverifiedUser={validationState.userToUnverify} />;
  }

  if (validationState.userToUnverify) {
    return (
      <ProfileUnvalidate
        showModal={!validationState.isCancelled}
        onCancel={() => dispatch({ type: reducerMap.verificationCancelled })}
        onUnverify={onUnverify}
        userToUnverify={validationState.userToUnverify}
      />
    );
  }

  return (
    <>
      <p>{t('profileValidate.profileValidationAuthenticatedUser.unknownErrorText')}</p>
    </>
  );
};
