/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    permissionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    organizationGroupId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    requireIdPorten?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    requireEmployeer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    requireUserOrganizationInMinSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    requiresMfa?: boolean;
}

export function PermissionResponseFromJSON(json: any): PermissionResponse {
    return PermissionResponseFromJSONTyped(json, false);
}

export function PermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissionId': !exists(json, 'permissionId') ? undefined : json['permissionId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'organizationGroupId': !exists(json, 'organizationGroupId') ? undefined : json['organizationGroupId'],
        'requireIdPorten': !exists(json, 'requireIdPorten') ? undefined : json['requireIdPorten'],
        'requireEmployeer': !exists(json, 'requireEmployeer') ? undefined : json['requireEmployeer'],
        'requireUserOrganizationInMinSide': !exists(json, 'requireUserOrganizationInMinSide') ? undefined : json['requireUserOrganizationInMinSide'],
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
    };
}

export function PermissionResponseToJSON(value?: PermissionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissionId': value.permissionId,
        'name': value.name,
        'shortName': value.shortName,
        'description': value.description,
        'applicationName': value.applicationName,
        'organizationGroupId': value.organizationGroupId,
        'requireIdPorten': value.requireIdPorten,
        'requireEmployeer': value.requireEmployeer,
        'requireUserOrganizationInMinSide': value.requireUserOrganizationInMinSide,
        'requiresMfa': value.requiresMfa,
    };
}


