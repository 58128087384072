import React from 'react';
import Table from 'react-bootstrap/Table';

interface IProps {
  captionText?: string;
  totalHits?: number;
  children?: React.ReactNode;
}

function TableLight({ totalHits, captionText, children }: IProps) {
  return (
    <>
      <Table className='table-light'>
        {captionText && <caption>{captionText}</caption>}
        {children}
      </Table>
      {totalHits !== undefined && totalHits <= 0 && <p style={{ textAlign: 'center' }}>Ingen data ble funnet</p>}
    </>
  );
}

export default TableLight;
