import { useFetch } from 'common.ui';
import { useEffect, useState } from 'react';
import { userMigrationClient } from 'api/MinSideClients';
import { ApiUserMigrationStatusPutRequest, UserMigrationStatus, UserMigrationStatusResponse } from 'api/minside';
import { useMsal } from 'auth/MsalContext';
import { useLocation } from 'react-router-dom';

export type IUserMigrationStatus = [UserMigrationStatus | undefined, boolean, (status: UserMigrationStatus) => void];

export type UserMigrationStatusResult = UserMigrationStatusResponse | undefined;

const userMigrationSessionName = 'UserMigrationStatus';

export function useUserMigrationStatus(): IUserMigrationStatus {
  const { pathname } = useLocation();
  const { isAuthenticated } = useMsal();
  const [migrationRequired, setMigrationRequired] = useState(false);
  const [migrationStatus, setMigrationStatus] = useState<UserMigrationStatus | undefined>(undefined);

  const [fetchedStatus] = useFetch<UserMigrationStatusResult>(
    () => fetchStatus(),
    [undefined] as UserMigrationStatusResult,
    false,
    [isAuthenticated]
  );

  const fetchStatus = async (): Promise<UserMigrationStatusResult> => {
    if (!isAuthenticated) {
      return undefined;
    }
    const result = await userMigrationClient.apiUserMigrationStatusGet();
    if (!result) {
      return undefined;
    }
    return result;
  };

  const getSessionStatus = (): UserMigrationStatus | null => {
    const value = window.sessionStorage.getItem(userMigrationSessionName);
    const status = value as UserMigrationStatus | null;
    return status;
  };

  const setSessionStatus = (status: UserMigrationStatus) => {
    window.sessionStorage.setItem(userMigrationSessionName, status);
  };

  const setStatus = async (status: UserMigrationStatus) => {
    const request = {
      userMigrationStatusUpdateRequest: {
        migrationStatus: status
      }
    } as ApiUserMigrationStatusPutRequest;
    await userMigrationClient.apiUserMigrationStatusPut(request);
    setSessionStatus(status);
    setMigrationStatus(status);
  };

  useEffect(() => {
    const session = getSessionStatus();
    const status = session || (fetchedStatus && fetchedStatus.migrationStatus);
    setMigrationStatus(status);

    const required = status && [UserMigrationStatus.FirstAccess, UserMigrationStatus.Postponed].includes(status);
    const requiredForSession = (required && !session) || false;
    setMigrationRequired(requiredForSession);
  }, [pathname, fetchedStatus, migrationStatus]);

  return [migrationStatus, migrationRequired, setStatus];
}
