/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyUserRequest
 */
export interface VerifyUserRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyUserRequest
     */
    accessToken?: string | null;
}

export function VerifyUserRequestFromJSON(json: any): VerifyUserRequest {
    return VerifyUserRequestFromJSONTyped(json, false);
}

export function VerifyUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
    };
}

export function VerifyUserRequestToJSON(value?: VerifyUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
    };
}


