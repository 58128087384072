import React, { useState } from 'react';
import { ApplicationResponse } from 'api/minside';
import Page from 'components/page/Page';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useBoundForm } from 'common.ui';
import Col from 'react-bootstrap/Col';
import './ApplicationList.scss';
import { useTranslation } from 'react-i18next';

export type Props = {
  applications: ApplicationResponse[];
  onSelect: (application: ApplicationResponse) => void;
};

type ApplicationSearchForm = {
  search: String;
};
function ApplicationList({ applications, onSelect }: Props) {
  const { t } = useTranslation('components');
  const [search, setSearch] = useState('');
  const { form, FormContainer, Input } = useBoundForm<ApplicationSearchForm>({
    onSubmit: async () => {}
  });

  return (
    <Page header={t('access.applicationList.title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={12} xl={8}>
            <p>
              <b>{t('access.applicationList.description')}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={5}>
            <FormContainer form={form}>
              <Input
                form={form}
                name='search'
                label={t('access.applicationList.form.searchLabel')}
                placeholder={t('access.applicationList.form.searchPlaceholder')}
                onChange={(s) => setSearch(s)}
              />
            </FormContainer>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={12} xl={8}>
            {' '}
            {applications
              .filter((a) => a.name?.toLowerCase().includes(search.toLowerCase()))
              .map((a) => (
                <div
                  aria-hidden='true'
                  key={`app${a.id}`}
                  className={`application-list-app-container ${a.requireIdPorten ? 'id-porten' : ''}`}
                  onClick={() => onSelect(a)}
                  onKeyDown={() => onSelect(a)}
                >
                  <div className='application-container'>
                    <div className='application-container__text'>
                      <h2 key={a.id ?? ''}>{a.name}</h2>
                      <div>{a.description}</div>
                    </div>
                    {a.requireIdPorten && (
                      <div className='application-container__id-porten'>
                        <img className='id-porten-image' src='/idporten.png' alt='ID Porten' />
                        <div className='id-porten-description'>
                          {' '}
                          {t('access.applicationList.list.idPortenRequirementDisclaimer')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default ApplicationList;
