import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from 'components/page/Page';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { defaultCountries, parseCountry, ParsedCountry, PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Button, SelectOption, useBoundForm } from 'common.ui';
import {
  ApplicationResponse,
  AzureUserError,
  EndUserRequest,
  GenericValidationError,
  GivenRoleDto,
  OrganizationResponse
} from 'api/minside';
import { usersClient } from 'api/MinSideClients';
import { useUserApplications } from 'api/hooks/useUserApplications';
import { useRoles } from 'api/hooks/useRoles';
import { useOrganizationsV2 } from 'api/hooks/useOrganizationsV2';
import { store } from 'react-notifications-component';
import { useUser } from 'contexts/UserContext';

import 'react-international-phone/style.css';

type UserInformationFormValue = {
  applicationId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  oldUserId?: string;
  givenRole?: GivenRoleDto;
};

type PhoneMetaData = {
  country: ParsedCountry;
  inputValue: string;
};

function CreateEndUser() {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneUtil = PhoneNumberUtil.getInstance();

  const { t } = useTranslation('pages');
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();

  const { preferredLangauge } = useUser();
  const [applications] = useUserApplications();
  const [selectedApplication, setSelectedApplication] = useState<ApplicationResponse | undefined>(undefined);
  const [role, setRole] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');

  const [roles, data] = useRoles(selectedApplication?.id, false, true);

  const [organizationGroupIds, setOrganizationGroupIds] = useState<number[] | null | undefined>();

  const [organizations] = useOrganizationsV2(organizationGroupIds!, [organizationGroupIds]);

  const [phone, setPhone] = useState('');
  const [parsedPhone, setParsedPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [values, setValues] = useState<UserInformationFormValue>({
    applicationId: applications?.[0]?.id ?? '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    oldUserId: '',
    givenRole: undefined
  });

  useEffect(() => {
    if (values.applicationId === undefined || values.applicationId === '') {
      setSelectedApplication(applications?.find((a) => a.id === applications?.[0]?.id));
    } else {
      setSelectedApplication(applications?.find((a) => a.id === values?.applicationId));
    }
  }, [applications, values]);

  useEffect(() => {
    setRole(roles?.[0]?.id || '');
    if (roles !== undefined && roles.length > 0) {
      const orgGroupIds = data?.filter((d) => d.roleId?.toString() === roles[0].id)[0];
      setOrganizationGroupIds(orgGroupIds?.organizationGroupIds);
    }
  }, [data, roles]);

  useEffect(() => {
    if (organizations?.length && organizations.length >= 1) {
      if (organizations[0].organizationId) {
        const orgId = organizations[0].organizationId?.toString()

        setSelectedOrganization(orgId);
      }
    }
  }, [organizations]);

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['no', 'se'].includes(iso2);
  });

  const validatePhone = useCallback(
    (phone: string) => {
      try {
        const region = phoneUtil.getRegionCodeForNumber(phoneUtil.parseAndKeepRawInput(phone));

        return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone, region), region);
      } catch {
        return false;
      }
    },
    [phoneUtil]
  );

  const { form, FormContainer, Input, Dropdown } = useBoundForm<UserInformationFormValue>({
    onSubmit: async (model) => {
      if (!validatePhone(phone)) {
        setIsValidPhone(false);
        return;
      }

      const userRequest: EndUserRequest = {
        ...model,
        email: isValidEmail ? email : '',
        phoneNumber: parsedPhone,
        givenRole: {
          id: role ? parseInt(role) : undefined,
          organizationId: selectedOrganization ? parseInt(selectedOrganization) : undefined,
          expiredAt: undefined
        }
      };

      try {
        const response = await usersClient.endUserPost({
          endUserRequest: userRequest
        });

        store.addNotification({
          title: t('createUser.message.title'),
          message: t('createUser.message.info', { displayName: response.displayName }),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 10000
          }
        });

        setApiErrors(undefined);
        setValues({
          ...values,
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          oldUserId: ''
        });
        setPhone('');
        form.reset();
      } catch (e) {
        if (e instanceof Response && e?.status === 400) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
        if (e instanceof Response && e?.status === 409) {
          const result = (await e.json()) as AzureUserError;
          if (result) {
            setApiErrors(undefined);
            store.addNotification({
              title: 'Error',
              message: result.errors?.email || result.errors?.phoneNumber,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 10000
              }
          });
          }
        }
      }
    },
    errors: apiErrors,
    model: values
  });

  const handlePhoneChange = (phone: string, meta: PhoneMetaData) => {
    const phoneNoDialCode = meta.inputValue.split(' ').slice(1).join('');
    const mobile = '+' + meta.country.dialCode + ' ' + phoneNoDialCode;

    setParsedPhone(mobile);
  };

  const validateEmail = (emailInput: string) => {
    const isValidEmail = emailRegex.test(emailInput);
    setEmail(emailInput);
    setIsValidEmail(isValidEmail);
  };

  const organizationOptions = useMemo(() => {
    return (
      organizations?.map((org: OrganizationResponse) => {
        return {
          id: org.organizationId?.toString(),
          text: org.name
        } as SelectOption;
      }) ?? []
    );
  }, [organizations]);

  const shouldRenderOrganizationDropDown = useMemo(() => {
    return (
      organizationGroupIds !== undefined &&
      organizationGroupIds !== null &&
      organizationGroupIds?.length > 0 &&
      organizationOptions.length > 0
    );
  }, [organizationGroupIds, organizationOptions.length]);

  return (
    <Page header={t('createUser.title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={12} xl={8}>
            <p>{t('createUser.description')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <h3>{t('createUser.roleInforTitle')}</h3>
          </Col>
        </Row>
        <FormContainer form={form}>
          <Row>
            <Col sm={6} lg={4}>
              <Dropdown
                form={form}
                onChange={(e) => {
                  setValues({
                    ...values,
                    applicationId: e.target.value
                  });
                }}
                options={applications ?? []}
                label={t('createUser.user.form.appLabel')}
                name='applicationId'
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={4}>
              <Dropdown
                form={form}
                onChange={(e) => {
                  setRole(e.target.value);
                  const orgGroupIds = data?.filter((d) => d.roleId?.toString() === e.target.value)[0];
                  setOrganizationGroupIds(orgGroupIds?.organizationGroupIds);
                }}
                options={roles || []}
                label={t('createUser.user.form.roleLabel')}
                name='givenRole'
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={4}>
              {shouldRenderOrganizationDropDown && (
                <Dropdown
                  name='givenRole.organizationId'
                  form={form}
                  options={organizationOptions}
                  label={t('createUser.user.form.organizationLabel')}
                  onChange={(e) => {
                    setSelectedOrganization(e.target.value);
                  }}
                />
              )}
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col sm={4}>
              <h3>{t('createUser.userinforTitle')}</h3>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col sm={12} lg={2}>
              <Input
                form={form}
                name='firstName'
                label={t('createUser.user.form.firstNameLabel')}
                onChange={(value) => {
                  setValues({
                    ...values,
                    firstName: value
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={2}>
              <Input
                form={form}
                name='lastName'
                label={t('createUser.user.form.lastNameLabel')}
                onChange={(value) => {
                  setValues({
                    ...values,
                    lastName: value
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={2}>
              <div className='my-2'>
                <div className='d-block mb-2 lh-1'>{t('createUser.user.form.phoneLabel')}</div>
                {!isValidPhone && <div className='text-error'>{t('createUser.user.form.phoneValidationMessage')}</div>}
                <PhoneInput
                  defaultCountry={preferredLangauge && preferredLangauge === 'Swedish' ? 'se' : 'no'}
                  forceDialCode={true}
                  countries={countries}
                  inputStyle={{
                    borderRadius: '0px',
                    border: '1px solid #8A8A8A',
                    height: '44px',
                    width: '100%'
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      height: '44px',
                      borderRadius: '0px',
                      border: '1px solid #8A8A8A'
                    }
                  }}
                  value={phone}
                  onChange={(phone, meta) => {
                    setPhone(phone);
                    handlePhoneChange(phone, meta);
                  }}
                />
              </div>
            </Col>
            <Col sm={12} lg={2}>
              <Input
                form={form}
                name='email'
                label={t('createUser.user.form.emailLabel')}
                onChange={(value) => {
                  validateEmail(value);
                }}
              />
            </Col>
            {/* <Col sm={12} lg={2}>
            <Input
                form={form}
                name='expirayDate'
                label={'Varihet'}
                onChange={(value) => {
                  setValues({
                    ...values,

                  });
                }}
              />
            </Col> */}
            <Col sm={12} lg={2}>
              <Input
                form={form}
                name='oldUserId'
                label={t('createUser.user.form.oldIdLabel')}
                onChange={(value) => {
                  setValues({
                    ...values,
                    oldUserId: value
                  });
                }}
              />
            </Col>
          </Row>
          <Button
            type='submit'
            text={t('createUser.user.form.buttonLabel')}
            onClick={() => {
              setIsValidPhone(true);
            }}
          />
        </FormContainer>
      </Container>
    </Page>
  );
}

export default CreateEndUser;
