/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApprovedAccessOrderBy {
    UserDisplayName = 'UserDisplayName',
    UserEmail = 'UserEmail',
    UserMfaPhone = 'UserMfaPhone',
    AccessName = 'AccessName',
    AssignedOn = 'AssignedOn',
    ApplicationName = 'ApplicationName'
}

export function ApprovedAccessOrderByFromJSON(json: any): ApprovedAccessOrderBy {
    return ApprovedAccessOrderByFromJSONTyped(json, false);
}

export function ApprovedAccessOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovedAccessOrderBy {
    return json as ApprovedAccessOrderBy;
}

export function ApprovedAccessOrderByToJSON(value?: ApprovedAccessOrderBy | null): any {
    return value as any;
}

