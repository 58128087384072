/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeFromJSONTyped,
    OrganizationGroupTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    organizationId?: number;
    /**
     * 
     * @type {OrganizationGroupType}
     * @memberof OrganizationResponse
     */
    organizationGroupType?: OrganizationGroupType;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationResponse
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    note?: string | null;
}

export function OrganizationResponseFromJSON(json: any): OrganizationResponse {
    return OrganizationResponseFromJSONTyped(json, false);
}

export function OrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'organizationGroupType': !exists(json, 'organizationGroupType') ? undefined : OrganizationGroupTypeFromJSON(json['organizationGroupType']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function OrganizationResponseToJSON(value?: OrganizationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'organizationGroupType': OrganizationGroupTypeToJSON(value.organizationGroupType),
        'isActive': value.isActive,
        'code': value.code,
        'name': value.name,
        'note': value.note,
    };
}


