/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GivenRoleDto,
    GivenRoleDtoFromJSON,
    GivenRoleDtoFromJSONTyped,
    GivenRoleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EndUserRequest
 */
export interface EndUserRequest {
    /**
     * 
     * @type {string}
     * @memberof EndUserRequest
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndUserRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndUserRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndUserRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndUserRequest
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndUserRequest
     */
    externalApplicationUserId?: string | null;
    /**
     * 
     * @type {GivenRoleDto}
     * @memberof EndUserRequest
     */
    givenRole?: GivenRoleDto;
}

export function EndUserRequestFromJSON(json: any): EndUserRequest {
    return EndUserRequestFromJSONTyped(json, false);
}

export function EndUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EndUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'externalApplicationUserId': !exists(json, 'externalApplicationUserId') ? undefined : json['externalApplicationUserId'],
        'givenRole': !exists(json, 'givenRole') ? undefined : GivenRoleDtoFromJSON(json['givenRole']),
    };
}

export function EndUserRequestToJSON(value?: EndUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'externalApplicationUserId': value.externalApplicationUserId,
        'givenRole': GivenRoleDtoToJSON(value.givenRole),
    };
}


