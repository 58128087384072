import Environment from 'Environment';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { B2cPolicies, B2cScopes, ProfileValidateReturnUri } from './AzureB2cConfig';

class IdPortenAuthenticationStore {
  public manager: UserManager;

  constructor() {
    const config: UserManagerSettings = {
      authority: `${B2cPolicies.authorities.idPortenValidation.authority}/v2.0`,
      client_id: Environment.MsalClientId,
      redirect_uri: ProfileValidateReturnUri,
      // post_logout_redirect_uri: `${window.location.origin}/idPorten/FrontChannelLogout`,
      scope: `${B2cScopes.join(' ')} openid profile offline_access`,
      acr_values: 'Level3',
      ui_locales: 'nb',
      loadUserInfo: false,
      response_mode: 'fragment',
      response_type: 'code',
      revokeAccessTokenOnSignout: true
    };

    this.manager = new UserManager(config);
  }

  login() {
    this.manager.signinRedirect().catch((error) => IdPortenAuthenticationStore.handleError(error));
  }

  async completeLogin(): Promise<User> {
    const user = await this.manager.signinRedirectCallback();
    await this.manager.storeUser(user);
    return user;
  }

  logout() {
    this.manager.signoutRedirect().catch((error) => IdPortenAuthenticationStore.handleError(error));
  }

  completeLogout() {
    this.manager
      .signoutRedirectCallback()
      .then(() => {
        this.manager.removeUser();
      })
      .then(() => {})
      .catch((error) => IdPortenAuthenticationStore.handleError(error));
  }

  static handleError(error: any) {
    console.error('Problem with authentication endpoint: ', error);
  }
}

const AuthStore = new IdPortenAuthenticationStore();
export default AuthStore;
