import { MinSideAccessControl } from 'api/minside/models';
import { SidebarItem } from 'components/pageTemplate/Sidebar';
import { NavigationKeys } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

export interface RouteItem {
  link: string;
  label?: string;
  labelTranslationKey?: NavigationKeys;
  hidden?: boolean;
  icon?: React.ReactNode;
  requiredPermission?: MinSideAccessControl;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  children?: RouteItem[];
}

export const toSidebarItem = (item: RouteItem): SidebarItem => {
  return {
    children: item.children?.filter((c) => !c.hidden).map((c) => toSidebarItem(c)),
    component: item.component,
    link: item.link,
    icon: item.icon,
    label: item.label,
    labelTranslationKey: item.labelTranslationKey
  } as SidebarItem;
};

export function flattenRoutes(items: RouteItem[]): RouteItem[] {
  return items
    ? items.reduce(
        (result, item) => [
          ...result,
          {
            link: item.link,
            label: item.label,
            labelTranslationKey: item.labelTranslationKey,
            component: item.component
          },
          ...flattenRoutes(item.children ?? [])
        ],
        [] as RouteItem[]
      )
    : ([] as RouteItem[]);
}

export function isValidRoutePath(routePath: string | undefined): boolean {
  if (!routePath) return false;
  return Object.values(RoutePaths).some((x) => x === routePath);
}
