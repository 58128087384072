import React, { useState } from 'react';
import Page from 'components/page/Page';
import {
  LoadingSpinner,
  useFetch,
  Button,
  ConfirmationModal,
  useBoundForm,
  Th,
  UnpagedTable,
  Checkbox as NoEmployerCheckBox
} from 'common.ui';
import { legalEntitiesClient, profileClient, externalUserEmployerClient } from 'api/MinSideClients';

import { BrregSearchItem, EmployerDependentAccessesResponse } from 'api/minside/models';
import { ApiLegalEntitiesSearchGetRequest } from 'api/minside/apis/LegalEntitiesApi';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { useTranslation } from 'react-i18next';

type EmployerSearchForm = {
  employer?: string | null;
};

type RequestChangeEmployerForm = {
  acceptRequirement?: boolean;
};

const UserInfo = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'profile.configureEmployer' });
  const [user] = useFetch(async () => profileClient.apiProfileCurrentGet(), undefined, false, []);
  const [values, setValues] = useState<EmployerSearchForm>({});
  const [employerChangeRequest, setEmployerChangeRequest] = useState<BrregSearchItem>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [noEmployer, setNoEmployer] = useState(false);
  const history = useHistory();

  const [users, isLoading] = useFetch<BrregSearchItem[]>(() => fetchUserEvents(), [] as BrregSearchItem[], false, [
    values.employer
  ]);

  const [dependentAccesses, isFetchingDependentAccesses, , fetchEmployerDependentRoles] = useFetch<
    EmployerDependentAccessesResponse | undefined
  >(() => externalUserEmployerClient.apiExternalUserEmployerGet(), undefined, false);

  const fetchUserEvents = async () => {
    const request: ApiLegalEntitiesSearchGetRequest = {
      name: values.employer ?? undefined
    };
    if (!values.employer) {
      return [] as BrregSearchItem[];
    }
    return legalEntitiesClient.apiLegalEntitiesSearchGet(request);
  };

  function runSearch(e: EmployerSearchForm) {
    setValues(e);
  }

  async function requestChangeEmployer(item: BrregSearchItem) {
    setEmployerChangeRequest(item);
    await fetchEmployerDependentRoles();
  }

  function onCancelDelete() {
    setEmployerChangeRequest(undefined);
    setAcceptedTerms(false);
    setNoEmployer(false);
  }

  const { form: confirmForm, FormContainer: ConfirmFormContainer, Checkbox } = useBoundForm<RequestChangeEmployerForm>({
    onSubmit: async () => {}
  });

  async function onDeleteConfirmed() {
    setIsUpdating(true);
    if (employerChangeRequest) {
      const item = employerChangeRequest;
      await externalUserEmployerClient.apiExternalUserEmployerPost({
        changeEmployerRequest: {
          address: item.address,
          county: item.county,
          countyNumber: item.countyNumber,
          municipality: item.municipality,
          municipalityNumber: item.municipalityNumber,
          organisationCompanyType: item.organisationCompanyType,
          organisationName: item.organisationName,
          organisationNumber: item.organisationNumber,
          postalCity: item.postalCity,
          postalCode: item.postalCode
        }
      });
    } else if (noEmployer) {
      await externalUserEmployerClient.apiExternalUserEmployerPost({
        changeEmployerRequest: {
          address: null,
          county: null,
          countyNumber: null,
          municipality: null,
          municipalityNumber: null,
          organisationCompanyType: 'UTLA',
          organisationName: null,
          organisationNumber: null,
          postalCity: null,
          postalCode: null
        }
      });
    }
    history.push(RoutePaths.profile);
  }

  const { form, Input, FormContainer, DisplayErrors } = useBoundForm<EmployerSearchForm>({
    onSubmit: async (e) => {
      // await OnFormSubmit(e);
      runSearch(e);
    },
    model: values
  });

  const header = user?.employer ? t('title.changeEmployer') : t('title.chooseEmployer');
  if (isUpdating || !user) {
    return <LoadingSpinner />;
  }

  return (
    <Page header={header}>
      <ConfirmationModal
        header={t('confirmationModal.title')}
        show={employerChangeRequest !== undefined}
        disabled={!acceptedTerms}
        onCancel={onCancelDelete}
        onAccept={onDeleteConfirmed}
        yesText={t('confirmationModal.btnYes')}
        noText={t('confirmationModal.btnNo')}
      >
        {isFetchingDependentAccesses && <LoadingSpinner />}
        {!isFetchingDependentAccesses &&
          ((dependentAccesses?.permissions && dependentAccesses.permissions?.length > 0) ||
            (dependentAccesses?.roles && dependentAccesses.roles?.length > 0)) && (
            <p>
              <b>{t('confirmationModal.text')}</b>
              <ul>
                {dependentAccesses.roles && dependentAccesses.roles?.length > 0 && (
                  <li>
                    <b>
                      {' '}
                      {t('confirmationModal.lblRolesNr')}: {dependentAccesses.roles.length}{' '}
                    </b>
                  </li>
                )}
                {dependentAccesses.permissions && dependentAccesses.permissions?.length > 0 && (
                  <li>
                    <b>
                      {' '}
                      {t('confirmationModal.lblPermissionsNr')}: {dependentAccesses.permissions.length}{' '}
                    </b>
                  </li>
                )}
              </ul>
            </p>
          )}
        {!isFetchingDependentAccesses && (
          <ConfirmFormContainer form={confirmForm}>
            <Checkbox
              form={confirmForm}
              name='acceptRequirement'
              question=''
              label={
                noEmployer
                  ? t('confirmationModal.lblAcceptRequirementNoEmployer')
                  : t('confirmationModal.lblAcceptRequirement')
              }
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
          </ConfirmFormContainer>
        )}
      </ConfirmationModal>{' '}
      <Container fluid>
        {user.employer && (
          <Row>
            <Col sm={12} lg={6}>
              <b>{t('lblCurrentEmployer')}:</b>
            </Col>
            <Col sm={12} lg={6}>
              {user.employer === 'UTLA' ? t('lbForeignEmployer') : user.employer}
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6} lg={4}>
            <FormContainer form={form}>
              <DisplayErrors form={form} />
              <Input
                form={form}
                label={t('lblSearchEmployer')}
                name='employer'
                onEnterPressed={(e) => {
                  setNoEmployer(false);
                  runSearch({
                    ...values,
                    employer: e
                  });
                }}
              />
              <Button type='submit' text={t('btnSubmit')} />
            </FormContainer>
          </Col>
        </Row>
        <Row>
          <NoEmployerCheckBox
            id='noEmployer'
            labelText={t('employer')}
            checked={user.employer === 'UTLA'}
            onChange={(e) => {
              setNoEmployer(e.target.checked);
              if (e.target.checked) {
                requestChangeEmployer({
                  address: null,
                  county: null,
                  countyNumber: null,
                  municipality: null,
                  municipalityNumber: null,
                  organisationCompanyType: 'UTLA',
                  organisationName: null,
                  organisationNumber: null,
                  postalCity: null,
                  postalCode: null
                });
              }
            }}
          />
        </Row>
        {isLoading && isFetchingDependentAccesses && <LoadingSpinner />}
        {users.length > 0 && (
          <Row>
            <Col sm={12}>
              <UnpagedTable
                totalHits={users.length}
                noRowsFoundText={t('table.noRowsFoundText')}
                renderRowCount={(rowsNr) => t('table.rowCountText', { rowsNr })}
              >
                <thead>
                  <tr>
                    <Th title={t('table.headers.organizationNumber')} />
                    <Th title={t('table.headers.name')} />
                    <Th title={t('table.headers.organizationType')} />
                    <Th title={t('table.headers.address')} />
                    <Th title={t('table.headers.postCode')} />
                    <Th title={t('table.headers.city')} />
                    <Th title='' />
                  </tr>
                </thead>
                <tbody>
                  {users.map((u) => (
                    <tr key={u.organisationNumber || ''}>
                      <td>{u.organisationNumber}</td>
                      <td>{u.organisationName}</td>
                      <td>{u.organisationCompanyType}</td>
                      <td>{u.address}</td>
                      <td>{u.postalCode}</td>
                      <td>{u.postalCity}</td>
                      <td>
                        {/* eslint-disable-next-line */}
                        <a href='#' onClick={() => requestChangeEmployer(u)}>
                          {t('table.columns.requestChangeLink')}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </UnpagedTable>
            </Col>
          </Row>
        )}
      </Container>
    </Page>
  );
};

export default UserInfo;
