/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleListItemDto
 */
export interface ArticleListItemDto {
    /**
     * 
     * @type {number}
     * @memberof ArticleListItemDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItemDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItemDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItemDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItemDto
     */
    ingress?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ArticleListItemDto
     */
    changed?: Date;
    /**
     * 
     * @type {Array<ArticleListItemDto>}
     * @memberof ArticleListItemDto
     */
    children?: Array<ArticleListItemDto> | null;
}

export function ArticleListItemDtoFromJSON(json: any): ArticleListItemDto {
    return ArticleListItemDtoFromJSONTyped(json, false);
}

export function ArticleListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleListItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'ingress': !exists(json, 'ingress') ? undefined : json['ingress'],
        'changed': !exists(json, 'changed') ? undefined : (new Date(json['changed'])),
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(ArticleListItemDtoFromJSON)),
    };
}

export function ArticleListItemDtoToJSON(value?: ArticleListItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'name': value.name,
        'icon': value.icon,
        'ingress': value.ingress,
        'changed': value.changed === undefined ? undefined : (value.changed.toISOString()),
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(ArticleListItemDtoToJSON)),
    };
}


