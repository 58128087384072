const { host } = window.location;
let browserEnv = '';
if (host.startsWith('localhost')) {
  browserEnv = 'local';
} else if (host.split('.')[0].endsWith('-minside-d')) {
  browserEnv = 'dev';
} else if (host.split('.')[0].endsWith('-minside-t') || host.split('.')[1].endsWith('test')) {
  browserEnv = 'test';
} else {
  browserEnv = 'production';
}

interface IEnvironment {
  ApiBaseUrl: string;
  MsalClientId: string;
  MsalScope: string;
  MsalInternalUserAuthority: string;
  MsalExternalUserAuthority: string;
  MsalPasswordResetAuthority: string;
  IdPortenValidateAuthority: string;
  StatsforvalterenDomainHint: string;
  Current: 'local' | 'dev' | 'test' | 'production';
}

let env: IEnvironment | undefined;

if (browserEnv === 'local' || browserEnv === 'dev')
  env = {
    ApiBaseUrl: browserEnv === 'local' ? 'https://localhost:4001' : 'https://minsideapi-minside-d.miljodirektoratet.no',
    MsalClientId: '87e0c34f-d149-4e0a-a140-86f979d0b13a',
    MsalScope: 'https://miljodirb2cdev.onmicrosoft.com/87e0c34f-d149-4e0a-a140-86f979d0b13a/default',
    MsalInternalUserAuthority:
      'https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/B2C_1A_signup_signin_internal_user',
    MsalExternalUserAuthority:
      'https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/B2C_1A_signup_signin_external_user',
    MsalPasswordResetAuthority:
      'https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/B2C_1A_password_reset',
    IdPortenValidateAuthority:
      'https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/B2C_1A_idporten_validation',
    StatsforvalterenDomainHint: 'statsforvalteren.onmicrosoft.com',
    Current: browserEnv
  };
else if (browserEnv === 'test')
  env = {
    ApiBaseUrl: 'https://minsideapi-minside-t.miljodirektoratet.no',
    MsalClientId: '7c532e17-83d6-48a5-85c0-426ba2b509e8',
    MsalScope: 'https://miljodirb2ctest.onmicrosoft.com/7c532e17-83d6-48a5-85c0-426ba2b509e8/default',
    MsalInternalUserAuthority:
      'https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/B2C_1A_signup_signin_internal_user',
    MsalExternalUserAuthority:
      'https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/B2C_1A_signup_signin_external_user',
    MsalPasswordResetAuthority:
      'https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/B2C_1A_password_reset',
    IdPortenValidateAuthority:
      'https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/B2C_1A_idporten_validation',
    StatsforvalterenDomainHint: 'statsforvalteren.onmicrosoft.com',
    Current: browserEnv
  };
else if (browserEnv === 'production')
  env = {
    ApiBaseUrl: 'https://minsideapi-minside-p.miljodirektoratet.no',
    MsalClientId: 'e861fc3f-95ab-4895-b43c-ff05a1d0f023',
    MsalScope: 'https://miljodirb2cprod.onmicrosoft.com/96984284-0be0-47c4-9dee-3af1ed9aa6ce/minside.api.default',
    MsalInternalUserAuthority:
      'https://miljodirb2cprod.b2clogin.com/miljodirb2cprod.onmicrosoft.com/B2C_1A_signup_signin_internal_user',
    MsalExternalUserAuthority:
      'https://miljodirb2cprod.b2clogin.com/miljodirb2cprod.onmicrosoft.com/B2C_1A_signup_signin_external_user',
    MsalPasswordResetAuthority:
      'https://miljodirb2cprod.b2clogin.com/miljodirb2cprod.onmicrosoft.com/B2C_1A_password_reset',
    IdPortenValidateAuthority:
      'https://miljodirb2cprod.b2clogin.com/miljodirb2cprod.onmicrosoft.com/B2C_1A_idporten_validation',
    StatsforvalterenDomainHint: 'statsforvalteren.onmicrosoft.com',
    Current: browserEnv
  };

if (env === undefined) throw new Error('Environment variables not set.');

const Environment = env;

export default Environment;
