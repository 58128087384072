/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseFromJSONTyped,
    PermissionResponseToJSON,
    RoleResponse,
    RoleResponseFromJSON,
    RoleResponseFromJSONTyped,
    RoleResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployerDependentAccessesResponse
 */
export interface EmployerDependentAccessesResponse {
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof EmployerDependentAccessesResponse
     */
    roles?: Array<RoleResponse> | null;
    /**
     * 
     * @type {Array<PermissionResponse>}
     * @memberof EmployerDependentAccessesResponse
     */
    permissions?: Array<PermissionResponse> | null;
}

export function EmployerDependentAccessesResponseFromJSON(json: any): EmployerDependentAccessesResponse {
    return EmployerDependentAccessesResponseFromJSONTyped(json, false);
}

export function EmployerDependentAccessesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployerDependentAccessesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleResponseFromJSON)),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(PermissionResponseFromJSON)),
    };
}

export function EmployerDependentAccessesResponseToJSON(value?: EmployerDependentAccessesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleResponseToJSON)),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(PermissionResponseToJSON)),
    };
}


