import React from 'react';
import Page from 'components/page/Page';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

export const ProfileAlreadyValidated = () => {
  const { t } = useTranslation('components', { keyPrefix: 'profileValidate.profileAlreadyValidated' });
  return (
    <Page header={t('title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            {t('text')}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
