import { useState, useEffect } from 'react';
import { AclConfig, userAcl } from 'api/AccessControlListMiddleware';
import { MinSideAccessControl } from 'api/minside';

export function useAccessControlList(): [
  MinSideAccessControl[] | undefined,
  (access: MinSideAccessControl) => boolean
] {
  const [aclConfig, setAclConfig] = useState<AclConfig | undefined>(userAcl.config);
  const [acl, setAcl] = useState<MinSideAccessControl[] | undefined>(userAcl.config?.acl);
  const hasAccess = (access: MinSideAccessControl): boolean => acl?.some((x) => x === access) ?? false;

  useEffect(() => {
    const action = () => {
      if (aclConfig?.jwtTokenSignature !== userAcl.config?.jwtTokenSignature) {
        setAclConfig(userAcl.config);
        setAcl(userAcl.config?.acl ?? []);
      }
    };
    action();
    const interval = setInterval(action, 1000);
    return () => clearInterval(interval);
  }, [aclConfig]);

  return [acl, hasAccess];
}
