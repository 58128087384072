import React from 'react';
import { ArticleListItemDto } from 'api/minside';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './FaqList.scss';

export type Props = {
  introText: string;
  articles: ArticleListItemDto[];
  onSelect: (article: ArticleListItemDto) => void;
};

function FaqList({ articles, onSelect, introText }: Props) {
  return (
    <Container fluid>
      <Row>
        <Col sm={12} lg={12} xl={8}>
          <p>
            <b>{introText}</b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12} xl={8}>
          {' '}
          {articles.map((a) => (
            <div
              aria-hidden='true'
              key={`app${a.id}`}
              className='faq-list-app-container'
              onClick={() => onSelect(a)}
              onKeyDown={() => onSelect(a)}
            >
              <div className='faq-container'>
                <div className='faq-container__text'>
                  <h2 key={a.id ?? ''}>{a.name}</h2>
                  <div>{a.ingress}</div>
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default FaqList;
