import { profileClient } from 'api/MinSideClients';
import { useFetch } from 'common.ui';

type IdPortenStatusResult = [boolean, boolean];

export function useIdPortenStatus(): IdPortenStatusResult {
  const [fetchedStatus] = useFetch<IdPortenStatusResult>(
    () => fetchStatus(),
    [false, true] as IdPortenStatusResult,
    false,
    []
  );

  const fetchStatus = async (): Promise<IdPortenStatusResult> => {
    const result = await profileClient.apiProfileUserVerificationGet();
    return [result?.isValidated ?? false, false];
  };

  return fetchedStatus;
}
