/* eslint-disable no-console */
import Environment from 'Environment';
import { LanguageStorageService } from 'localization/LanguageStorageService';
import { FetchParams, Middleware, RequestContext } from './minside/runtime';

class AddLanguageMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  async pre?(context: RequestContext): Promise<FetchParams | void> {
    // TODO: Get token language if we don't have a starting point?
    const language = LanguageStorageService.getPreferredLanguageOrDefault();

    const headers = context.init.headers as Record<string, string>;
    headers['accept-language'] = language.shortName;

    if (['dev', 'local', 'test'].includes(Environment.Current)) console.log({ language: language.id });

    return context;
  }
}

export default AddLanguageMiddleware;
