import React from 'react';
import Page from 'components/page/Page';
import { Dropdown, LoadingSpinner, SelectOption, useFetch } from 'common.ui';
import { profileClient } from 'api/MinSideClients';
import { UserType } from 'api/minside';
import Container from 'react-bootstrap/Container';
import { UserPropertyGroup } from 'components/profile/UserPropertyGroup';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'localization/SupportedLanguage';
import { supportedLanguages } from 'localization/LanguageConfig';
import { useUser } from 'contexts/UserContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InternalUserInfo from './InternalUserInfo';
import ExternalUserInfo from './ExternalUserInfo';

function UserInfo() {
  const { t } = useTranslation('pages');
  const { changePreferredLanguage, preferredLangauge } = useUser();
  const [user] = useFetch(async () => profileClient.apiProfileCurrentGet(), undefined, false, []);

  const langs: SelectOption<SupportedLanguage>[] = supportedLanguages.map((x) => {
    return { id: x.id, text: x.displayName, value: x };
  });

  if (!user) {
    return <LoadingSpinner />;
  }

  return (
    <Page header={t('userInfo.title')}>
      {user.userType === UserType.Internal && <InternalUserInfo user={user} />}
      {user.userType === UserType.External && <ExternalUserInfo user={user} />}

      <Container fluid>
        <Row>
          <Col lg='8'>
            <UserPropertyGroup label={t('userInfo.languageSelector.label')} />
            <Dropdown
              options={langs}
              id='langSelector'
              key={preferredLangauge}
              labelText={t('userInfo.languageSelector.labelText')}
              value={preferredLangauge}
              onChangeSelection={async (_k, v) => {
                const val = v as SupportedLanguage;
                await changePreferredLanguage(val.id);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default UserInfo;
