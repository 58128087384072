import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserProfileResponse, UserType } from 'api/minside';
import { Link } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { useTranslation } from 'react-i18next';
import { UserProperty } from './UserProperty';

export type IProps = {
  user: UserProfileResponse;
};

function Employer(user: UserProfileResponse) {
  const { t } = useTranslation('components');
  if (user.userType === UserType.Internal) {
    return <>{user.employer}</>;
  }
  if (user.employer === 'UTLA') {
    return (
      <>
        {t('profile.employer.foreign')} (
        <Link to={RoutePaths.profileConfigureEmployer}>{t('profile.employer.change')}</Link>)
      </>
    );
  }
  return user.employer ? (
    <>
      {user.employer} (<Link to={RoutePaths.profileConfigureEmployer}>{t('profile.employer.change')}</Link>)
    </>
  ) : (
    <Link to={RoutePaths.profileConfigureEmployer}>{t('profile.employer.choose')}</Link>
  );
}

export function UserData({ user }: IProps) {
  const { t } = useTranslation('components');
  return (
    <>
      <Row>
        <Col lg='8'>
          <UserProperty label={t('profile.userData.nameLabel')} value={user.name} />
          {/* <UserProperty label='Telefon' value={user.telefonNr} /> */}
          <UserProperty label={t('profile.userData.emailUsernameLabel')} value={user.email} />
          <UserProperty label={t('profile.userData.employerLabel')} value={Employer(user)} />
          {user.statsforvalterenOrganizationGroup?.organizations?.length && (
            <UserProperty
              label={t('profile.userData.statsforvalterenBranchLabel')}
              value={user.statsforvalterenOrganizationGroup.organizations[0].name}
            />
          )}
          {user.mfaPhoneNumber && (
            <UserProperty
              label={t('profile.userData.telephoneLabel')}
              value={`${user.mfaPhoneNumber} ${t('profile.userData.telephoneMfaDisclaimer')}`}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
