import { RoleResponse, RoleSearchOrderBy, SortOrder } from 'api/minside';
import { rolesClient } from 'api/MinSideClients';
import { SelectOption, useFetch } from 'common.ui';

export type RolesResult = [SelectOption[] | undefined, RoleResponse[] | undefined];

export function useRoles(applicationId?: string | null, searchAllApplicationsIfNull?: boolean | null, onlyApprovedByExternalAppAdmin?: boolean | null): RolesResult {
  const [fetchedRoles] = useFetch<RolesResult>(() => fetchAllRoles(), [undefined, undefined] as RolesResult, false, [
    applicationId
  ]);

  const fetchAllRoles = async (): Promise<RolesResult> => {
    if (!applicationId && searchAllApplicationsIfNull === false) {
      return [undefined, undefined] as RolesResult;
    }
    const roles = await rolesClient.apiRolesGet({
      pageNumber: 1,
      pageSize: 9999,
      sortOrder: SortOrder.Ascending,
      orderField: RoleSearchOrderBy.Name,
      applicationId: applicationId ?? undefined,
      onlyApprovedByAppAdmin: onlyApprovedByExternalAppAdmin ?? undefined
    });

    if (!roles.data) {
      return [undefined, undefined] as RolesResult;
    }

    const options = roles.data.map((d) => {
      return {
        id: d.roleId?.toString(),
        text: d.name
      } as SelectOption;
    });

    const data = roles.data || [];

    return [options, data];
  };

  return fetchedRoles;
}
