/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AzureUserError,
    AzureUserErrorFromJSON,
    AzureUserErrorToJSON,
    EndUserRequest,
    EndUserRequestFromJSON,
    EndUserRequestToJSON,
    ExternalEndUserResponse,
    ExternalEndUserResponseFromJSON,
    ExternalEndUserResponseToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
} from '../models';

export interface EndUserPostRequest {
    endUserRequest?: EndUserRequest;
}

export interface UserIdPermissionsPermissionIdDeleteRequest {
    userId: string;
    permissionId: number;
}

export interface UserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRequest {
    userId: string;
    permissionId: number;
    organizationId: number;
}

export interface UserIdRolesRoleIdDeleteRequest {
    userId: string;
    roleId: number;
}

export interface UserIdRolesRoleIdOrganizationOrganizationIdDeleteRequest {
    userId: string;
    roleId: number;
    organizationId: number;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async endUserPostRaw(requestParameters: EndUserPostRequest): Promise<runtime.ApiResponse<ExternalEndUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/end-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EndUserRequestToJSON(requestParameters.endUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalEndUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async endUserPost(requestParameters: EndUserPostRequest): Promise<ExternalEndUserResponse> {
        const response = await this.endUserPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userIdPermissionsPermissionIdDeleteRaw(requestParameters: UserIdPermissionsPermissionIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userIdPermissionsPermissionIdDelete.');
        }

        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling userIdPermissionsPermissionIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{userId}/permissions/{permissionId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userIdPermissionsPermissionIdDelete(requestParameters: UserIdPermissionsPermissionIdDeleteRequest): Promise<void> {
        await this.userIdPermissionsPermissionIdDeleteRaw(requestParameters);
    }

    /**
     */
    async userIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRaw(requestParameters: UserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userIdPermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling userIdPermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling userIdPermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{userId}/permissions/{permissionId}/organization/{organizationId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userIdPermissionsPermissionIdOrganizationOrganizationIdDelete(requestParameters: UserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.userIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

    /**
     */
    async userIdRolesRoleIdDeleteRaw(requestParameters: UserIdRolesRoleIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userIdRolesRoleIdDelete.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling userIdRolesRoleIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{userId}/roles/{roleId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userIdRolesRoleIdDelete(requestParameters: UserIdRolesRoleIdDeleteRequest): Promise<void> {
        await this.userIdRolesRoleIdDeleteRaw(requestParameters);
    }

    /**
     */
    async userIdRolesRoleIdOrganizationOrganizationIdDeleteRaw(requestParameters: UserIdRolesRoleIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userIdRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling userIdRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling userIdRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{userId}/roles/{roleId}/organization/{organizationId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userIdRolesRoleIdOrganizationOrganizationIdDelete(requestParameters: UserIdRolesRoleIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.userIdRolesRoleIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

}
