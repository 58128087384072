import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { formatDatetime, LoadingSpinner, useFetch } from 'common.ui';
import { profileClient } from 'api/MinSideClients';
import TableLight from 'components/table-light/TableLight';

function UserConsents() {
  const [events] = useFetch(async () => profileClient.apiProfileConsentsGet(), undefined, false, []);

  if (!events) {
    return <LoadingSpinner />;
  }

  return (
    <Container fluid>
      <Row>
        <Col lg='10'>
          <TableLight captionText='Mine samtykker' totalHits={events.length}>
            <thead>
              <tr>
                <th>Dato/tid</th>
                <th>Hva</th>
              </tr>
            </thead>
            <tbody>
              {events
                ?.sort((u1, u2) => (u2.createdOn?.valueOf() || 0) - (u1.createdOn?.valueOf() || 0))
                .map((e) => (
                  <tr key={e.userEventId}>
                    <td className='text-nowrap'>{formatDatetime(e.createdOn)}</td>
                    <td>{e.eventDescription}</td>
                  </tr>
                ))}
            </tbody>
          </TableLight>
        </Col>
      </Row>
    </Container>
  );
}

export default UserConsents;
