import React from 'react';
import { LoadingSpinner, useFetch, PagedTable, ThSortable, useSortedPaging } from 'common.ui';
import { accessRequestClient } from 'api/MinSideClients';
import { AccessRequestResponsePagedResult, AccessRequestSearchOrderBy, SortOrder } from 'api/minside';
import Page from 'components/page/Page';
import { Link } from 'react-router-dom';

import { Container } from 'react-bootstrap';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import RoutePaths from 'RoutePaths';
import { useTranslation } from 'react-i18next';

function AccessRequests() {
  const { t } = useTranslation('pages', { keyPrefix: 'accessRequest.accessRequests' });
  const [accessRequestsPaged, isLoading, , fetchAccessRequests] = useFetch<AccessRequestResponsePagedResult>(
    () => fetchPagedAccessRequests(),
    {} as AccessRequestResponsePagedResult,
    false
  );

  const [startIndex, currentPage, pageSize, sorting, onNextPage, onPreviousPage, , setSorting] = useSortedPaging<
    AccessRequestSearchOrderBy
  >(fetchAccessRequests, {
    field: AccessRequestSearchOrderBy.RequestedByUserName,
    direction: 'asc'
  });

  const fetchPagedAccessRequests = async (): Promise<AccessRequestResponsePagedResult> => {
    return accessRequestClient.apiAccessRequestsGet({
      pageSize,
      pageNumber: currentPage,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field
    });
  };

  const handleSort = (e: SortEvent<AccessRequestSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header={t('title')}>
      <Container fluid>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PagedTable
              startIndex={startIndex}
              totalHits={accessRequestsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
              noRowsFoundText={t('noRowsFoundText')}
              renderRowCount={(rowsNr) => t('rowCountText', { rowsNr })}
              nextText={t('tablePagination.nextText')}
              previousText={t('tablePagination.previousText')}
              ofText={t('tablePagination.ofText')}
            >
              <thead>
                <tr>
                  <ThSortable
                    title={t('tableHeaders.name')}
                    field={AccessRequestSearchOrderBy.RequestedByUserName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title={t('tableHeaders.organization')}
                    field={AccessRequestSearchOrderBy.RequestedByUserSectionName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title={t('tableHeaders.accessRightName')}
                    field={AccessRequestSearchOrderBy.RequestedAccessName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title={t('tableHeaders.applicationName')}
                    field={AccessRequestSearchOrderBy.ApplicationName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                </tr>
              </thead>

              <tbody>
                {accessRequestsPaged?.data?.map((u) => (
                  <tr key={u.uuid ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.accessRequests}/${u.uuid}`}>{u.requestedByUserName}</Link>
                    </td>
                    <td>{u.organization?.name || u.employer}</td>
                    <td>{u.requestedRoleName || u.requestedPermissionName}</td>
                    <td>{u.applicationDescription || u.applicationName}</td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          </>
        )}
      </Container>
    </Page>
  );
}

export default AccessRequests;
