import React, { FunctionComponent } from 'react';
import { PageHeader } from 'common.ui';
import './Page.scss';

interface IProps {
  header?: string;
  stepCurrent?: number;
  stepTotal?: number;
  renderStepInfo?: (stepCurrent: number, stepTotal: number) => string;
}

const Page: FunctionComponent<IProps> = ({ header, stepCurrent, stepTotal, children, renderStepInfo }) => {
  return (
    <div className='page-container'>
      {header && (
        <PageHeader value={header} stepCurrent={stepCurrent} stepTotal={stepTotal} renderStepInfo={renderStepInfo} />
      )}
      {children}
    </div>
  );
};

export default Page;
