import React, { useEffect, useState } from 'react';
import Page from 'components/page/Page';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/UserContext';
import { Language } from 'api/minside';
import { Button } from 'common.ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserConsents from './UserConsents';
import UserEvents from './UserEvents';

function Privacy() {
  const { t } = useTranslation('pages', { keyPrefix: 'privacy.privacy' });

  const { preferredLangauge } = useUser();
  const isNorwegian = preferredLangauge === Language.Norwegian;
  const [showPrivacyInfo, setShowPrivacyInfo] = useState<boolean>(isNorwegian);

  useEffect(() => {
    setShowPrivacyInfo(isNorwegian);
  }, [isNorwegian]);

  return (
    <Page header={t('title')}>
      {!isNorwegian && (
        <Row>
          <Col sm={12} lg={8}>
            <p> {t('localizationDisclaimer')} </p>
            <Button
              id='btnShowPrivacyInfo'
              text={showPrivacyInfo ? t('btnShowPrivacyInfoText.visible') : t('btnShowPrivacyInfoText.hidden')}
              onClick={() => setShowPrivacyInfo(!showPrivacyInfo)}
            />
          </Col>
        </Row>
      )}

      {showPrivacyInfo && (
        <React.Fragment>
          <Row>
            <Col sm={12} lg={10}>
              <UserConsents />
              <UserEvents />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Page>
  );
}

export default Privacy;
