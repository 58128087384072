/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeFromJSONTyped,
    OrganizationGroupTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMigrationQueryPermissionResponse
 */
export interface UserMigrationQueryPermissionResponse {
    /**
     * 
     * @type {number}
     * @memberof UserMigrationQueryPermissionResponse
     */
    permissionId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryPermissionResponse
     */
    permissionName?: string | null;
    /**
     * 
     * @type {OrganizationGroupType}
     * @memberof UserMigrationQueryPermissionResponse
     */
    requiredOrganizationGroupType?: OrganizationGroupType;
}

export function UserMigrationQueryPermissionResponseFromJSON(json: any): UserMigrationQueryPermissionResponse {
    return UserMigrationQueryPermissionResponseFromJSONTyped(json, false);
}

export function UserMigrationQueryPermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationQueryPermissionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissionId': !exists(json, 'permissionId') ? undefined : json['permissionId'],
        'permissionName': !exists(json, 'permissionName') ? undefined : json['permissionName'],
        'requiredOrganizationGroupType': !exists(json, 'requiredOrganizationGroupType') ? undefined : OrganizationGroupTypeFromJSON(json['requiredOrganizationGroupType']),
    };
}

export function UserMigrationQueryPermissionResponseToJSON(value?: UserMigrationQueryPermissionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissionId': value.permissionId,
        'permissionName': value.permissionName,
        'requiredOrganizationGroupType': OrganizationGroupTypeToJSON(value.requiredOrganizationGroupType),
    };
}


