import React, { useState } from 'react';
import Page from 'components/page/Page';
import { Container, Row, Col } from 'react-bootstrap';
import { UserMigrationQueryResponse, UserMigrationStatus } from 'api/minside';
import RoutePaths from 'RoutePaths';
import { Redirect } from 'react-router-dom';
import MigrationWizardIntroduction from 'components/user-migration/MigrationWizardIntroduction';
import MigrationWizardSelection, { UserDataForm } from 'components/user-migration/MigrationWizardSelection';
import MigrationWizardVerification from 'components/user-migration/MigrationWizardVerification';
import { useUserMigrationStatus } from 'api/hooks/useUserMigrationStatus';
import { useMigrationWizardApplications } from 'api/hooks/useMigrationWizardApplications';
import { LoadingSpinner } from 'common.ui';
import { useTranslation } from 'react-i18next';

export enum Steps {
  intro = 1,
  selection = 2,
  verification = 3,
  completed = 4,
  canceled = 5
}

function MigrationWizard() {
  const { t } = useTranslation('pages', { keyPrefix: 'userMigration.migrationWizard' });
  const [applicationOptions, applications] = useMigrationWizardApplications();
  const [, , setMigrationStatusSession] = useUserMigrationStatus();
  const [userData, setUserData] = useState<UserDataForm>({ applicationId: '', username: '', password: '' });
  const [accesses, setAccesses] = useState<UserMigrationQueryResponse[]>([]);
  const [step, setStep] = useState<Steps>(Steps.intro);

  const onMigrationCompleted = async (status: UserMigrationStatus) => {
    await setMigrationStatusSession(status);
  };

  const onQueryCompleted = (user: UserDataForm, value: UserMigrationQueryResponse[]) => {
    setUserData(user);
    setAccesses(value);
    setStep(Steps.verification);
  };

  if (!applications || !applicationOptions) {
    return <LoadingSpinner />;
  }

  return (
    <Page
      header={t('title')}
      stepCurrent={1}
      stepTotal={3}
      renderStepInfo={(stepCurrent, stepTotal) => t('renderStepInfo', { stepCurrent, stepTotal })}
    >
      <Container fluid>
        <Row>
          <Col sm={10} lg={10}>
            {step === Steps.intro && (
              <MigrationWizardIntroduction
                applications={applications}
                onMigrationCompleted={onMigrationCompleted}
                setStep={setStep}
              />
            )}
            {step === Steps.selection && (
              <MigrationWizardSelection
                applications={applications}
                applicationOptions={applicationOptions}
                onMigrationCompleted={onMigrationCompleted}
                onQueryCompleted={onQueryCompleted}
                setStep={setStep}
              />
            )}
            {step === Steps.verification && (
              <MigrationWizardVerification
                userData={userData}
                accesses={accesses}
                onMigrationCompleted={onMigrationCompleted}
                setStep={setStep}
              />
            )}
            {[Steps.completed, Steps.canceled].includes(step) && <Redirect to={RoutePaths.access} />}
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default MigrationWizard;
