import { useMsal } from 'auth/MsalContext';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import Page from '../../components/page/Page';

function PostLogout() {
  const { isAuthenticated, authInProgress } = useMsal();
  const { t } = useTranslation('pages', { keyPrefix: 'postLogout.postLogout' });
  const { replace } = useHistory();
  useEffect(() => {
    if (isAuthenticated && !authInProgress) replace(RoutePaths.home);
  });

  return (
    <Page header={t('title')}>
      <p>{t('text')}</p>
    </Page>
  );
}

export default PostLogout;
