/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserIdentityOrigin,
    UserIdentityOriginFromJSON,
    UserIdentityOriginFromJSONTyped,
    UserIdentityOriginToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface VerifiedUserResponse
 */
export interface VerifiedUserResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifiedUserResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifiedUserResponse
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifiedUserResponse
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifiedUserResponse
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifiedUserResponse
     */
    email?: string | null;
    /**
     * 
     * @type {UserIdentityOrigin}
     * @memberof VerifiedUserResponse
     */
    identityOrigin?: UserIdentityOrigin;
    /**
     * 
     * @type {boolean}
     * @memberof VerifiedUserResponse
     */
    isIdPortenUser?: boolean;
    /**
     * 
     * @type {UserType}
     * @memberof VerifiedUserResponse
     */
    userType?: UserType;
}

export function VerifiedUserResponseFromJSON(json: any): VerifiedUserResponse {
    return VerifiedUserResponseFromJSONTyped(json, false);
}

export function VerifiedUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifiedUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'identityOrigin': !exists(json, 'identityOrigin') ? undefined : UserIdentityOriginFromJSON(json['identityOrigin']),
        'isIdPortenUser': !exists(json, 'isIdPortenUser') ? undefined : json['isIdPortenUser'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
    };
}

export function VerifiedUserResponseToJSON(value?: VerifiedUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'givenName': value.givenName,
        'surname': value.surname,
        'displayName': value.displayName,
        'email': value.email,
        'identityOrigin': UserIdentityOriginToJSON(value.identityOrigin),
        'isIdPortenUser': value.isIdPortenUser,
        'userType': UserTypeToJSON(value.userType),
    };
}


