/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMigrationMigrateRoleRequest
 */
export interface UserMigrationMigrateRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof UserMigrationMigrateRoleRequest
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationMigrateRoleRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserMigrationMigrateRoleRequest
     */
    organizationId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserMigrationMigrateRoleRequest
     */
    userConfirmation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMigrationMigrateRoleRequest
     */
    userOrganizationConfirmation?: boolean;
}

export function UserMigrationMigrateRoleRequestFromJSON(json: any): UserMigrationMigrateRoleRequest {
    return UserMigrationMigrateRoleRequestFromJSONTyped(json, false);
}

export function UserMigrationMigrateRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationMigrateRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'userConfirmation': !exists(json, 'userConfirmation') ? undefined : json['userConfirmation'],
        'userOrganizationConfirmation': !exists(json, 'userOrganizationConfirmation') ? undefined : json['userOrganizationConfirmation'],
    };
}

export function UserMigrationMigrateRoleRequestToJSON(value?: UserMigrationMigrateRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'description': value.description,
        'organizationId': value.organizationId,
        'userConfirmation': value.userConfirmation,
        'userOrganizationConfirmation': value.userOrganizationConfirmation,
    };
}


