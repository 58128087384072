/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeFromJSONTyped,
    OrganizationGroupTypeToJSON,
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMigrationQueryRoleResponse
 */
export interface UserMigrationQueryRoleResponse {
    /**
     * 
     * @type {number}
     * @memberof UserMigrationQueryRoleResponse
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryRoleResponse
     */
    roleName?: string | null;
    /**
     * 
     * @type {OrganizationGroupType}
     * @memberof UserMigrationQueryRoleResponse
     */
    requiredOrganizationGroupType?: OrganizationGroupType;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof UserMigrationQueryRoleResponse
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
}

export function UserMigrationQueryRoleResponseFromJSON(json: any): UserMigrationQueryRoleResponse {
    return UserMigrationQueryRoleResponseFromJSONTyped(json, false);
}

export function UserMigrationQueryRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationQueryRoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'requiredOrganizationGroupType': !exists(json, 'requiredOrganizationGroupType') ? undefined : OrganizationGroupTypeFromJSON(json['requiredOrganizationGroupType']),
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
    };
}

export function UserMigrationQueryRoleResponseToJSON(value?: UserMigrationQueryRoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'roleName': value.roleName,
        'requiredOrganizationGroupType': OrganizationGroupTypeToJSON(value.requiredOrganizationGroupType),
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
    };
}


