/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import IdPortenAuthenticationStore from 'auth/IdPortenAuthenticationStore';
import { User } from 'oidc-client';
import { LoadingSpinner } from 'common.ui';
import { ProfileValidationAuthenticatedUser } from 'components/profile-validate/ProfileValidationAuthenticatedUser';
import { ProfileValidationLogin } from 'components/profile-validate/ProfileValidationLogin';
import { ProfileAlreadyValidated } from 'components/profile-validate/ProfileAlreadyValidated';
import { IdPortenValidatePolicyTokenClaims } from 'auth/IdPortenValidatePolicyTokenClaims';
import { useIdPortenStatus } from 'api/hooks/useIdPortenStatus';

export const ProfileValidationAuthContainer = () => {
  const { hash } = window.location;
  const params = new URLSearchParams(hash);

  const authorizationCodeFromReturnUrl = params.get('code');

  const [idPortenAccessToken, setIdPortenAccesstoken] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const login = () => {
    IdPortenAuthenticationStore.login();
  };

  const [isUserAlreadyVerified, isApiCallLoading] = useIdPortenStatus();

  useEffect(() => {
    async function initialize() {
      if (authorizationCodeFromReturnUrl) {
        await IdPortenAuthenticationStore.manager.clearStaleState();
        let authUser: User | undefined;
        try {
          authUser = await IdPortenAuthenticationStore.completeLogin();
        } catch (e) {
          // handle reloading page with same auth code in query url
          return;
        }

        // handle error here (if token was not obtained)
        if (authUser?.id_token) {
          setIdPortenAccesstoken((authUser?.profile as IdPortenValidatePolicyTokenClaims).idp_token);
        }
      }
    }

    if (!idPortenAccessToken) initialize().then(() => setIsLoading(false));
    else {
      setIsLoading(false);
    }
  }, [authorizationCodeFromReturnUrl, idPortenAccessToken]);

  if (isLoading || isApiCallLoading) return <LoadingSpinner />;

  if (isUserAlreadyVerified) return <ProfileAlreadyValidated />;

  if (idPortenAccessToken)
    return <ProfileValidationAuthenticatedUser idPortenAccessToken={idPortenAccessToken} onLogin={login} />;

  return <ProfileValidationLogin onLogin={login} />;
};
