import React from 'react';
import { LoadingSpinner } from 'common.ui';
import MigrationWizard from 'screens/user-migration/MigrationWizard';
import { MinSideAccessControl } from 'api/minside';
import AccessRequests from 'screens/access-request/AccessRequests';
import AccessRequest from 'screens/access-request/AccessRequest';
import ApprovedAccessRequests from 'screens/access-request/ApprovedAccessRequests';
import Permissions from './screens/access/Permissions';
import UserInfo from './screens/profile/UserInfo';
import { RouteItem } from './helpers/routing';
import RoutePaths from './RoutePaths';
import RequestAccess from './screens/access/RequestAccess';
import Validate from './screens/profile/Validate';
import ConfigureEmployer from './screens/profile/ConfigureEmployer';
import Privacy from './screens/privacy/Privacy';
import Faq from './screens/help/Faq';
import FaqItem from './screens/help/FaqItem';
import { useMsal } from './auth/MsalContext';
import { withAuth } from './auth/withAuth';

// import { ReactComponent as IconForside } from './assets/icons/home-icon.svg';
import { ReactComponent as IconProfil } from './assets/icons/profile-icon.svg';
import { ReactComponent as IconTilganger } from './assets/icons/access-key-icon.svg';
// import { ReactComponent as IconAbonnement } from './assets/icons/subscription-icon.svg';
import { ReactComponent as IconPersonvern } from './assets/icons/personvern-icon.svg';
import { ReactComponent as IconSikkerhet } from './assets/icons/security-icon.svg';
import { ReactComponent as IconHjelp } from './assets/icons/help-icon.svg';
import { ReactComponent as IconAddUser } from './assets/icons/users-add-icon.svg';
import CreateEndUser from 'screens/users/CreateEndUser';

const auth = (c: any) => {
  return () => {
    const { authInProgress } = useMsal();

    if (authInProgress) return <LoadingSpinner />;

    return <div>{withAuth(c)(c)}</div>;
  };
};

export const RouteConfig: RouteItem[] = [
  // {
  //   link: RoutePaths.home,
  //   labelTranslationKey: 'Forside',
  //   component: Tbd,
  //   icon: <IconForside />
  // },
  {
    // link: RoutePaths.profile,
    link: RoutePaths.profile,
    labelTranslationKey: 'profile.page',
    icon: <IconProfil />,
    component: auth(UserInfo),
    children: [
      {
        link: RoutePaths.profileValidate,
        labelTranslationKey: 'profile.idPortenValidation',
        component: auth(Validate)
      },
      // {
      //   link: RoutePaths.profileInfo,
      //   labelTranslationKey: 'Brukerinformasjon',
      //   component: auth(UserInfo)
      // },
      {
        link: RoutePaths.profileConfigureEmployer,
        labelTranslationKey: 'profile.configureEmployer',
        hidden: true,
        component: auth(ConfigureEmployer)
      }
    ]
  },
  {
    link: RoutePaths.access,
    labelTranslationKey: 'accessRights.page',
    icon: <IconTilganger />,
    component: Permissions,
    children: [
      {
        link: RoutePaths.accessMigration,
        labelTranslationKey: 'accessRights.accessMigration',
        component: auth(MigrationWizard)
      },
      {
        link: RoutePaths.accessRequest,
        labelTranslationKey: 'accessRights.accessRequest',
        component: auth(RequestAccess)
      }
    ]
  },
  {
    link: RoutePaths.createEndUser,
    labelTranslationKey: 'user.page',
    requiredPermission: MinSideAccessControl.EndUserCreate,
    icon: <IconAddUser />,
    component: auth(CreateEndUser),
  },
  // {
  //   link: RoutePaths.subscriptions,
  //   labelTranslationKey: 'Abonnement',
  //   component: auth(Tbd),
  //   icon: <IconAbonnement />
  // },
  {
    link: RoutePaths.privacy,
    labelTranslationKey: 'privacy.page',
    component: auth(Privacy),
    icon: <IconPersonvern />
  },
  // {
  //   link: RoutePaths.security,
  //   labelTranslationKey: 'Sikkerhet',
  //   component: auth(Tbd),
  //   icon: <IconSikkerhet />
  // },
  {
    link: RoutePaths.help,
    labelTranslationKey: 'help.page',
    component: auth(Faq),
    icon: <IconHjelp />
  },
  {
    link: RoutePaths.faqPage,
    labelTranslationKey: 'faq.page',
    component: auth(FaqItem),
    hidden: true
  },
  {
    link: RoutePaths.accessRequests,
    requiredPermission: MinSideAccessControl.AccessRequestsView,
    labelTranslationKey: 'accessRequests.page',
    component: auth(AccessRequests),
    icon: <IconSikkerhet />,
    children: [
      {
        link: RoutePaths.accessRequestsApproved,
        requiredPermission: MinSideAccessControl.AccessRequestsView,
        labelTranslationKey: 'accessRequests.approvedRights',
        component: auth(ApprovedAccessRequests)
      }
    ]
  },
  {
    link: RoutePaths.accessRequestDetail,
    requiredPermission: MinSideAccessControl.AccessRequestsEdit,
    labelTranslationKey: 'accessRequests.detail',
    hidden: true,
    component: auth(AccessRequest),
    icon: <IconSikkerhet />
  }
];
