/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAuthenticationConstraint {
    None = 'None',
    IdPortenAlways = 'IdPortenAlways',
    IdPortenVerified = 'IdPortenVerified',
    Anonymous = 'Anonymous',
    RequiresChildConfiguration = 'RequiresChildConfiguration'
}

export function UserAuthenticationConstraintFromJSON(json: any): UserAuthenticationConstraint {
    return UserAuthenticationConstraintFromJSONTyped(json, false);
}

export function UserAuthenticationConstraintFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthenticationConstraint {
    return json as UserAuthenticationConstraint;
}

export function UserAuthenticationConstraintToJSON(value?: UserAuthenticationConstraint | null): any {
    return value as any;
}

