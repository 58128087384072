/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMigrationQueryApplicationResponse
 */
export interface UserMigrationQueryApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryApplicationResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryApplicationResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationQueryApplicationResponse
     */
    applicationUrl?: string | null;
}

export function UserMigrationQueryApplicationResponseFromJSON(json: any): UserMigrationQueryApplicationResponse {
    return UserMigrationQueryApplicationResponseFromJSONTyped(json, false);
}

export function UserMigrationQueryApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationQueryApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'applicationUrl': !exists(json, 'applicationUrl') ? undefined : json['applicationUrl'],
    };
}

export function UserMigrationQueryApplicationResponseToJSON(value?: UserMigrationQueryApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'applicationUrl': value.applicationUrl,
    };
}


