/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GivenRoleDto
 */
export interface GivenRoleDto {
    /**
     * 
     * @type {number}
     * @memberof GivenRoleDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GivenRoleDto
     */
    organizationId?: number;
    /**
     * 
     * @type {Date}
     * @memberof GivenRoleDto
     */
    expiredAt?: Date | null;
}

export function GivenRoleDtoFromJSON(json: any): GivenRoleDto {
    return GivenRoleDtoFromJSONTyped(json, false);
}

export function GivenRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GivenRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'expiredAt': !exists(json, 'expiredAt') ? undefined : (json['expiredAt'] === null ? null : new Date(json['expiredAt'])),
    };
}

export function GivenRoleDtoToJSON(value?: GivenRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organizationId': value.organizationId,
        'expiredAt': value.expiredAt === undefined ? undefined : (value.expiredAt === null ? null : value.expiredAt.toISOString()),
    };
}


