import { Language } from 'api/minside/models/Language';
import Environment from 'Environment';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enComponentTranslations from './resources/english/components.json';
import enFooterTranslations from './resources/english/footer.json';
import enNavigationTranslations from './resources/english/navigation.json';
import enPageTranslations from './resources/english/pages.json';
import nbComponentTranslations from './resources/norwegian/components.json';
import nbFooterTranslations from './resources/norwegian/footer.json';
import nbNavigationTranslations from './resources/norwegian/navigation.json';
import nbPageTranslations from './resources/norwegian/pages.json';
import svComponentTranslations from './resources/swedish/components.json';
import svFooterTranslations from './resources/swedish/footer.json';
import svNavigationTranslations from './resources/swedish/navigation.json';
import svPageTranslations from './resources/swedish/pages.json';
import { SupportedLanguage } from './SupportedLanguage';

export const defaultLanguage: SupportedLanguage = {
  displayName: 'Norsk',
  id: Language.Norwegian,
  shortName: 'nb',
  shortDisplayName: 'no'
};

export const supportedLanguages: SupportedLanguage[] = [
  defaultLanguage,
  {
    displayName: 'English',
    id: Language.English,
    shortName: 'en',
    shortDisplayName: 'en'
  },
  {
    displayName: 'Svenska',
    id: Language.Swedish,
    shortName: 'se',
    shortDisplayName: 'se'
  }
];

declare module 'react-i18next' {
  interface CustomTypeOptions {
    resources: {
      pages: typeof nbPageTranslations;
      components: typeof nbComponentTranslations;
      navigation: typeof nbNavigationTranslations;
      footer: typeof nbFooterTranslations;
    };
  }
}

type ResourcesType = {
  [language in Language]: {
    pages: typeof enPageTranslations;
    components: typeof enComponentTranslations;
    navigation: typeof enNavigationTranslations;
    footer: typeof enFooterTranslations;
  };
};

export const resources: ResourcesType = {
  [Language[Language.English]]: {
    pages: enPageTranslations,
    components: enComponentTranslations,
    navigation: enNavigationTranslations,
    footer: enFooterTranslations
  },
  [Language[Language.Norwegian]]: {
    pages: nbPageTranslations,
    components: nbComponentTranslations,
    navigation: nbNavigationTranslations,
    footer: nbFooterTranslations
  },
  [Language[Language.Swedish]]: {
    pages: svPageTranslations,
    components: svComponentTranslations,
    navigation: svNavigationTranslations,
    footer: svFooterTranslations
  }
} as const;

i18n.use(initReactI18next).init({
  debug: Environment.Current !== 'production',
  ns: ['pages', 'components', 'navigation', 'footer'],
  resources,
  fallbackLng: defaultLanguage.id
});

export default i18n;
