import { Language } from 'api/minside';
import { supportedLanguages, defaultLanguage } from './LanguageConfig';
import { SupportedLanguage } from './SupportedLanguage';

class LanguageStorageService {
  private preferredLanguageKey = 'preferredLanguage';

  private preferredLanguage: SupportedLanguage | undefined;

  constructor() {
    const key = window.localStorage.getItem(this.preferredLanguageKey) ?? undefined;
    this.preferredLanguage =
      supportedLanguages.filter((x) => x.id === Language[key as keyof typeof Language])[0] ?? undefined;
  }

  public getPreferredLanguageOrDefault(): SupportedLanguage {
    return this.preferredLanguage ?? defaultLanguage;
  }

  public setPreferredLanguage(lang: SupportedLanguage | undefined) {
    if (!lang) {
      window.localStorage.removeItem(this.preferredLanguageKey);
    } else {
      window.localStorage.setItem(this.preferredLanguageKey, Language[lang.id]);
    }

    this.preferredLanguage = lang;
  }
}

const ls = new LanguageStorageService();
export { ls as LanguageStorageService };
