import { useFetch } from 'common.ui';
import { DependencyList } from 'react';
import { organizationGroupsClient } from 'api/MinSideClients';
import { OrganizationResponse } from 'api/minside';

export function useOrganizationsV2(
  organizationGroupIds: Array<number | undefined>,
  dependencies: DependencyList = [] as DependencyList
): [OrganizationResponse[] | undefined] {
  const [fetchedOrganizations] = useFetch<OrganizationResponse[] | undefined>(
    () => fetchAllOrganizations(),
    undefined,
    false,
    [...dependencies]
  );

  const fetchAllOrganizations = async (): Promise<OrganizationResponse[] | undefined> => {
    if (organizationGroupIds?.length === 0 || organizationGroupIds.includes(undefined)) return undefined;
    const result = await organizationGroupsClient.apiOrganizationGroupsOrganizationsGet({
      pageNumber: 1,
      pageSize: 9999,
      filterByUserCreation: true,
      organizationGroupIds: organizationGroupIds as number[]
    });
    return result.data || undefined;
  };

  return [fetchedOrganizations];
}
