import { applicationsClient } from 'api/MinSideClients';
import { SelectOption, useFetch } from 'common.ui';
import { ApplicationResponse, ApplicationSearchOrderBy, SortOrder } from 'api/minside';

export type ApplicationsResult = [SelectOption[] | undefined, ApplicationResponse[] | undefined];

export function useApplications(): ApplicationsResult {
  const [fetchedApplications] = useFetch<ApplicationsResult>(
    () => fetchApplications(),
    [undefined, undefined] as ApplicationsResult,
    false,
    []
  );

  const fetchApplications = async (): Promise<ApplicationsResult> => {
    const applications = await applicationsClient.apiApplicationsGet({
      pageNumber: 1,
      pageSize: 9999,
      sortOrder: SortOrder.Ascending,
      orderField: ApplicationSearchOrderBy.Name
    });

    if (!applications.data) {
      return [undefined, undefined] as ApplicationsResult;
    }

    const data = applications.data || [];

    const options = data
      .map((app) => {
        return {
          id: app.id,
          text: app.description || app.name
        } as SelectOption;
      })
      .sort((o1, o2) => (o1.text || '').localeCompare(o2.text || ''));

    return [options, data];
  };

  return fetchedApplications;
}
