/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMigrationApplicationResponse
 */
export interface UserMigrationApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof UserMigrationApplicationResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationApplicationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationApplicationResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMigrationApplicationResponse
     */
    url?: string | null;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof UserMigrationApplicationResponse
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
}

export function UserMigrationApplicationResponseFromJSON(json: any): UserMigrationApplicationResponse {
    return UserMigrationApplicationResponseFromJSONTyped(json, false);
}

export function UserMigrationApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
    };
}

export function UserMigrationApplicationResponseToJSON(value?: UserMigrationApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'name': value.name,
        'description': value.description,
        'url': value.url,
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
    };
}


