import React from 'react';
import { Row, Col } from 'react-bootstrap';

export interface IProps {
  label: string;
  value?: string | JSX.Element | null;
  note?: string | JSX.Element | null;
}

export function UserProperty({ label, value, note }: IProps) {
  return (
    <Row>
      <Col lg='3'>
        <b>{label}</b>
      </Col>
      <Col lg={note === undefined ? 9 : 5}>{value}</Col>
      {note && <Col lg='4'>{note}</Col>}
    </Row>
  );
}
