import React from 'react';
import Page from 'components/page/Page';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SelectOption } from 'common.ui';
import './AccessList.scss';
import { ReactComponent as IconProfil } from 'assets/icons/profile-icon-black.svg';
import { useTranslation } from 'react-i18next';

export enum AccessType {
  role = 1,
  permission = 2
}

export type RequestedAccess = {
  type: AccessType;
  id: number;
  name: string;
  description: string;
  requiresIdPorten?: boolean;
  requireEmployeer?: boolean;
};

export type Props = {
  accesses: SelectOption<RequestedAccess>[];
  applicationName: string;
  onSelect: (application: RequestedAccess) => void;
};

function AccessList({ accesses, onSelect, applicationName }: Props) {
  const { t } = useTranslation('components');
  const accessList = accesses.filter((a) => a.value).map((a) => a.value as RequestedAccess);
  const getLocalizedAccessType = (type: AccessType) => {
    if (type === AccessType.permission) {
      return t('access.accessList.accessType.permission');
    }
    if (type === AccessType.role) {
      return t('access.accessList.accessType.role');
    }
    return '';
  };

  return (
    <Page header={t('access.accessList.title')}>
      <Container fluid>
        {accesses.length === 0 && (
          <Row>
            <Col sm={12} lg={8}>
              <div>{t('access.accessList.emptyListMsg')}</div>
            </Col>
          </Row>
        )}
        {accesses.length > 0 && (
          <>
            <Row>
              <Col sm={12} lg={8}>
                <b>{t('access.accessList.nonEmptyListMsg')}</b>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={8}>
                <h3>
                  {t('access.accessList.rolesAndpermissionsForApp')} {applicationName}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={8}>
                {accessList.map((a) => (
                  <div
                    aria-hidden='true'
                    className={`access-list-entry ${a.requiresIdPorten ? 'id-porten' : ''} ${
                      a.requireEmployeer ? 'employeer' : ''
                    }`}
                    key={`${a.type}${a.id}`}
                    onClick={() => onSelect(a)}
                    onKeyDown={() => onSelect(a)}
                  >
                    <div className='access-list-entry__text'>
                      <div className='access-list-entry__title'>{a.name}</div>
                      {a.description && <div className='access-list-entry__description'>{a.description}</div>}
                    </div>
                    <div className='access-list-entry__meta'>
                      <span>
                        {a.requiresIdPorten && (
                          <img
                            className='access-list-entry__idPorten'
                            src='/idporten.png'
                            width='18px'
                            alt='IdPorten'
                          />
                        )}
                        {a.requireEmployeer && <IconProfil style={{ marginRight: '5px', marginBottom: '3px' }} />}
                        {getLocalizedAccessType(a.type)}
                      </span>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Page>
  );
}

export default AccessList;
