/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventResponse
 */
export interface UserEventResponse {
    /**
     * 
     * @type {number}
     * @memberof UserEventResponse
     */
    userEventId?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserEventResponse
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserEventResponse
     */
    eventDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEventResponse
     */
    createdByDescription?: string | null;
}

export function UserEventResponseFromJSON(json: any): UserEventResponse {
    return UserEventResponseFromJSONTyped(json, false);
}

export function UserEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEventId': !exists(json, 'userEventId') ? undefined : json['userEventId'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'eventDescription': !exists(json, 'eventDescription') ? undefined : json['eventDescription'],
        'createdByDescription': !exists(json, 'createdByDescription') ? undefined : json['createdByDescription'],
    };
}

export function UserEventResponseToJSON(value?: UserEventResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEventId': value.userEventId,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'eventDescription': value.eventDescription,
        'createdByDescription': value.createdByDescription,
    };
}


