import React from 'react';
import { NavigationKeys, useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactComponent as IconArrowRight } from '../../assets/icons/arrow-right-icon.svg';
import './Sidebar.scss';

export interface SidebarItem {
  link: string;
  label?: string;
  labelTranslationKey?: NavigationKeys;
  icon?: React.ReactNode;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  children?: SidebarItem[];
}

interface IProp extends RouteComponentProps {
  routes: SidebarItem[];
}

const Sidebar = ({ routes, location }: IProp) => {
  const { t } = useTranslation('navigation');
  const getText = (item: SidebarItem): string => {
    if (item.labelTranslationKey !== undefined) {
      return t(item.labelTranslationKey) as string;
    }
    return item.label ?? '';
  };

  return (
    <div className='sidebar'>
      {routes.map((m) => (
        <MenuItem
          key={m.link}
          link={m.link}
          label={getText(m)}
          isActive={m.link === location.pathname}
          // isSubItemActive={
          //   m.link === location.pathname ||
          //   location.pathname.startsWith(`${m.link}/`) ||
          //   m.children?.some((c) => c.link === location.pathname)
          // }
          icon={m.icon}
        >
          <>
            {m.children?.map((c) => (
              <SubMenuItem
                key={c.link}
                link={c.link}
                label={getText(c)}
                icon={c.icon}
                isActive={c.link === location.pathname}
              />
            ))}
          </>
        </MenuItem>
      ))}
    </div>
  );
};

interface IMenuItemProps {
  icon?: React.ReactNode;
  isActive?: boolean;
  isSubItemActive?: boolean;
  label?: string;
  link: string;
  children?: React.ReactNode;
}

const MenuItem = ({ icon, isActive, label, link, children }: IMenuItemProps) => {
  return (
    <>
      <div className={`menu-list-item ${isActive ? 'list-item-active' : ''}`}>
        <Link to={link} title={label}>
          {icon}
          <span className='menu-item-text'>{label}</span>
        </Link>
      </div>
      {children}
      <div className='bottom-divider' />
    </>
  );
};

const SubMenuItem = ({ isActive, label, link }: IMenuItemProps) => {
  return (
    <div className={`menu-list-sub-item ${isActive ? 'list-sub-item-active' : ''}`}>
      <Link to={link} title={label}>
        <IconArrowRight className='sub-menu-item-icon' />
        <span className='menu-item-text'>{label}</span>
      </Link>
    </div>
  );
};

const sideBarWithRouter = withRouter(Sidebar);

export { sideBarWithRouter as Sidebar };
