/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleSearchOrderBy {
    Name = 'Name',
    ShortName = 'ShortName',
    ApplicationName = 'ApplicationName',
    UserType = 'UserType'
}

export function RoleSearchOrderByFromJSON(json: any): RoleSearchOrderBy {
    return RoleSearchOrderByFromJSONTyped(json, false);
}

export function RoleSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleSearchOrderBy {
    return json as RoleSearchOrderBy;
}

export function RoleSearchOrderByToJSON(value?: RoleSearchOrderBy | null): any {
    return value as any;
}

