/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {number}
     * @memberof RoleResponse
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleResponse
     */
    organizationGroupIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    requireIdPorten?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    requireEmployeer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    requireUserOrganizationInMinSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    requiresMfa?: boolean;
}

export function RoleResponseFromJSON(json: any): RoleResponse {
    return RoleResponseFromJSONTyped(json, false);
}

export function RoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'organizationGroupIds': !exists(json, 'organizationGroupIds') ? undefined : json['organizationGroupIds'],
        'requireIdPorten': !exists(json, 'requireIdPorten') ? undefined : json['requireIdPorten'],
        'requireEmployeer': !exists(json, 'requireEmployeer') ? undefined : json['requireEmployeer'],
        'requireUserOrganizationInMinSide': !exists(json, 'requireUserOrganizationInMinSide') ? undefined : json['requireUserOrganizationInMinSide'],
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
    };
}

export function RoleResponseToJSON(value?: RoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'name': value.name,
        'shortName': value.shortName,
        'description': value.description,
        'applicationName': value.applicationName,
        'organizationGroupIds': value.organizationGroupIds,
        'requireIdPorten': value.requireIdPorten,
        'requireEmployeer': value.requireEmployeer,
        'requireUserOrganizationInMinSide': value.requireUserOrganizationInMinSide,
        'requiresMfa': value.requiresMfa,
    };
}


