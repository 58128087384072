import Environment from 'Environment';
import {
  AccessRequestsApi,
  ApplicationsApi,
  RolesApi,
  ProfileApi,
  PermissionsApi,
  OrganizationGroupsApi,
  UserMigrationApi,
  LegalEntitiesApi,
  ExternalUserEmployerApi,
  MeApi,
  UsersApi,
  CmsApi
} from './minside';
import { Configuration } from './minside/runtime';
import AddTokenMiddleware from './AddTokenMiddleware';
import ApiExceptionMiddleware from './ApiExceptionMiddleware';
import AccessControlListMiddleware from './AccessControlListMiddleware';
import AddLanguageMiddleware from './AddLanguageMiddleware';

const config = new Configuration({
  basePath: Environment.ApiBaseUrl,
  middleware: [
    new AddTokenMiddleware(),
    new AddLanguageMiddleware(),
    new ApiExceptionMiddleware(),
    new AccessControlListMiddleware()
  ]
});

const accessRequestClient = new AccessRequestsApi(config);
const applicationsClient = new ApplicationsApi(config);
const rolesClient = new RolesApi(config);
const permissionsClient = new PermissionsApi(config);
const organizationGroupsClient = new OrganizationGroupsApi(config);
const profileClient = new ProfileApi(config);
const userMigrationClient = new UserMigrationApi(config);
const legalEntitiesClient = new LegalEntitiesApi(config);
const externalUserEmployerClient = new ExternalUserEmployerApi(config);
const meApiClient = new MeApi(config);
const cmsClient = new CmsApi(config);
const usersClient = new UsersApi(config);

export {
  accessRequestClient,
  applicationsClient,
  rolesClient,
  permissionsClient,
  organizationGroupsClient,
  profileClient,
  userMigrationClient,
  legalEntitiesClient,
  externalUserEmployerClient,
  meApiClient,
  usersClient,
  cmsClient
};
