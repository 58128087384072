/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserMigrationStatus {
    FirstAccess = 'FirstAccess',
    Postponed = 'Postponed',
    NotRequired = 'NotRequired',
    Completed = 'Completed'
}

export function UserMigrationStatusFromJSON(json: any): UserMigrationStatus {
    return UserMigrationStatusFromJSONTyped(json, false);
}

export function UserMigrationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationStatus {
    return json as UserMigrationStatus;
}

export function UserMigrationStatusToJSON(value?: UserMigrationStatus | null): any {
    return value as any;
}

