import React from 'react';
import Page from 'components/page/Page';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Button } from 'common.ui';
import { GenericValidationError } from 'common.ui/dist/hooks/form/useBoundForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  onLogin: () => void;
  validationError: GenericValidationError;
}

export const ProfileValidationError = ({ validationError, onLogin }: IProps) => {
  const { t } = useTranslation('components');
  return (
    <Page header={t('profileValidate.profileValidationError.title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            <p>{validationError?.messages ? validationError.messages[0] : null}</p>
            <Button showArrow text={t('profileValidate.profileValidationError.tryAgain')} onClick={onLogin} />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
