import React, { useState, useEffect } from 'react';
import * as msal from '@azure/msal-browser';
import { useRouteMatch, useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { LanguageStorageService } from 'localization/LanguageStorageService';
import { AuthService } from './AuthService';
import { B2cPolicies, StatsforvalterenDomainHint } from './AzureB2cConfig';
import { MsalNavigationClient } from './MsalNavigationClient';
import { IdTokenClaims } from './IdTokenClaims';

export interface IMsalContext {
  isAuthenticated: boolean;
  user: msal.AccountInfo | undefined;
  b2cAuthority: string | undefined;
  login: () => Promise<void>;
  resetPassword: (uiLocales?: string | undefined) => Promise<void>;
  logout: () => void;
  setB2cPolicy: (policyName: string) => void;
  isB2cPolicySet: () => boolean;
  ensureInternalUserLogin: () => Promise<void>;
  ensureStatsforvalterenLogin: () => Promise<void>;
  authInProgress: boolean;
}

export const MsalContext = React.createContext<IMsalContext | undefined>(undefined);

export const useMsal = () => {
  const context = React.useContext(MsalContext);
  if (context === undefined) {
    throw new Error('useMsal must be used within a MsalProvider');
  }
  return context;
};

export const MsalProvider = ({ children }: { children: any }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<msal.AccountInfo | undefined>();
  const [authInProgress, setAuthInProgress] = useState<boolean>(true);
  const [b2cAuthority, setB2cAuthority] = useState<string | undefined>(undefined);
  const passwordResetMatch = useRouteMatch(RoutePaths.passwordReset);
  const { push, replace } = useHistory();
  AuthService.setNavigationClient(new MsalNavigationClient(push));

  const onMsalEvent = (event: msal.EventMessage) => {
    if (event.eventType === msal.EventType.HANDLE_REDIRECT_END) {
      if (!AuthService.isLoggedIn()) {
        setB2cAuthority(B2cPolicies.authorities.externalUser.authority);
      } else {
        setB2cAuthority(AuthService.mapHomeAccountIdToAuthority(AuthService.getActiveAccount()?.homeAccountId));
      }

      setUser(AuthService.getActiveAccount());
      setIsAuthenticated(AuthService.isLoggedIn());
    }

    if (event.eventType === msal.EventType.ACQUIRE_TOKEN_FAILURE) {
      setIsAuthenticated(AuthService.isLoggedIn());
      setB2cAuthority(undefined);
      setUser(undefined);
    }

    if (event.eventType === msal.EventType.ACQUIRE_TOKEN_SUCCESS) {
      setUser(AuthService.getActiveAccount());
      setIsAuthenticated(AuthService.isLoggedIn());
      if (AuthService.isLoggedIn())
        setB2cAuthority(AuthService.mapHomeAccountIdToAuthority(AuthService.getActiveAccount()?.homeAccountId));
    }
  };

  useEffect(() => {
    const authenticateAsync = async () => {
      AuthService.setMsalCallback((event: msal.EventMessage) => {
        onMsalEvent(event);
      });
      try {
        setAuthInProgress(true);
        await AuthService.handleRedirect();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('HandleRedirect: ', e);
      } finally {
        setAuthInProgress(false);
      }
    };

    // skip handling authentication for password reset route
    if (!passwordResetMatch) {
      authenticateAsync();
    } else {
      // authInProgress is initiated to true so we can show loaders as early as possible
      // set to false for password reset scenario (no authentication)
      setAuthInProgress(false);
    }
  }, [passwordResetMatch]);

  const setB2cPolicy = (policyName: string) => {
    const authority = mapPolicyNameToAuthority(policyName);

    setB2cAuthority(authority);
  };

  const ensureInternalUserLogin = async () => {
    const currentUserAuthority = AuthService.mapHomeAccountIdToAuthority(AuthService.getActiveAccount()?.homeAccountId);
    const isInternalUser = B2cPolicies.authorities.internalUser.authority === currentUserAuthority;

    if (!isInternalUser && isAuthenticated) {
      await AuthService.signOut(RoutePaths.loginInternal);
      return;
    }

    if (!isInternalUser) {
      setAuthInProgress(true);
      await AuthService.signInRedirect(
        B2cPolicies.authorities.internalUser.authority,
        RoutePaths.home,
        undefined,
        undefined,
        LanguageStorageService.getPreferredLanguageOrDefault().shortName
      );
    } else {
      replace(RoutePaths.home);
    }
  };

  const ensureStatsforvalterenLogin = async () => {
    // eslint-disable-next-line camelcase
    const isStatsforvalteren = (user?.idTokenClaims as IdTokenClaims)?.idp_code === 'Statsforvalteren';

    if (!isStatsforvalteren && isAuthenticated) {
      await AuthService.signOut(RoutePaths.loginStatsforvalteren);
      return;
    }

    if (!isStatsforvalteren) {
      setAuthInProgress(true);
      await AuthService.signInRedirect(
        B2cPolicies.authorities.externalUser.authority,
        RoutePaths.home,
        StatsforvalterenDomainHint,
        true,
        LanguageStorageService.getPreferredLanguageOrDefault().shortName
      );
    } else {
      replace(RoutePaths.home);
    }
  };

  const mapPolicyNameToAuthority = (policyName: string) => {
    if (policyName === B2cPolicies.names.internalUser) return B2cPolicies.authorities.internalUser.authority;
    if (policyName === B2cPolicies.names.externalUser) return B2cPolicies.authorities.externalUser.authority;

    throw Error(`${policyName} is not a valid B2C policy name.`);
  };

  const isB2cPolicySet = () => {
    return b2cAuthority !== undefined;
  };

  const login = async () => {
    if (b2cAuthority === undefined) throw Error(`B2C policy is not set.`);

    setAuthInProgress(true);
    await AuthService.signInRedirect(
      b2cAuthority,
      undefined,
      undefined,
      undefined,
      LanguageStorageService.getPreferredLanguageOrDefault().shortName
    );
  };

  const resetPassword = async (uiLocales: string | undefined) => {
    await AuthService.resetPassword(uiLocales ?? LanguageStorageService.getPreferredLanguageOrDefault().shortName);
  };

  const logout = async () => {
    setAuthInProgress(true);
    await AuthService.signOut(RoutePaths.postLogout);
  };

  return (
    <MsalContext.Provider
      value={{
        isAuthenticated,
        user,
        b2cAuthority,
        authInProgress,
        login,
        resetPassword,
        logout,
        setB2cPolicy,
        isB2cPolicySet,
        ensureInternalUserLogin,
        ensureStatsforvalterenLogin
      }}
    >
      {children}
    </MsalContext.Provider>
  );
};
