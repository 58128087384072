import Environment from 'Environment';
import RoutePaths from 'RoutePaths';

const B2cPolicies = {
  names: {
    externalUser: 'B2C_1A_signup_signin_external_user',
    internalUser: 'B2C_1A_signup_signin_internal_user',
    passwordReset: 'B2C_1A_password_reset'
  },
  authorities: {
    externalUser: {
      authority: Environment.MsalExternalUserAuthority
    },
    internalUser: {
      authority: Environment.MsalInternalUserAuthority
    },
    passwordReset: {
      authority: Environment.MsalPasswordResetAuthority
    },
    idPortenValidation: {
      authority: Environment.IdPortenValidateAuthority
    }
  }
};

const B2cScopes = [Environment.MsalScope];

const PasswordResetReturnUri = `${window.location.origin}${RoutePaths.passwordReset}`;
const ProfileValidateReturnUri = `${window.location.origin}${RoutePaths.profileValidate}`;
const { StatsforvalterenDomainHint } = Environment;

export { B2cPolicies, B2cScopes, PasswordResetReturnUri, ProfileValidateReturnUri, StatsforvalterenDomainHint };
