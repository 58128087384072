import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { formatDate } from 'common.ui';
import { SapUserDataResponse, UserProfileResponse } from 'api/minside';
import AAData from 'components/profile/AAData';
import { UserData } from 'components/profile/UserData';
import KrrInfo from 'components/profile/KrrInfo';
// import AltinnRoles from 'components/profile/AltinnRoles';
import { UserPropertyGroup } from '../../components/profile/UserPropertyGroup';
import { UserProperty } from '../../components/profile/UserProperty';

type IProps = {
  user: UserProfileResponse;
};

function InternalUserInfo({ user }: IProps) {
  return (
    <Container fluid>
      <UserData user={user} />

      {user.sapUserData && <SapData user={user.sapUserData} />}
      {user.isValidated && (
        <>
          {user.userKrrInfo && <KrrInfo user={user.userKrrInfo} />}
          <AAData user={user} />
        </>
      )}
      {/* {user.altinnRoles && user.altinnRoles.length > 0 && <AltinnRoles roles={user.altinnRoles ?? []} />} */}
    </Container>
  );
}

function SapData({ user }: { user: SapUserDataResponse }) {
  return (
    <Row>
      <Col lg='8'>
        <UserPropertyGroup label='Intern bruker (SAP)' />
        {/* 
		TODO: waiting feedback from Marius
		<UserProperty label='Rolle i systemet' value='Saksbehandler' /> 
		*/}
        <UserProperty label='Stillingstittel' value={user.jobTitle} />
        <UserProperty label='Arbeidssted' value={user.personCity} />
        <UserProperty label='Seksjon' value={user.sectionCode} />
        <UserProperty label='Ansattnummer' value={user.sapUserId} />
        <UserProperty label='Brukernavn/Initialer' value={user.initials} />
        <UserProperty label='Virkingsdato' value={formatDate(user.employmentStartDate)} />
      </Col>
    </Row>
  );
}

export default InternalUserInfo;
