/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMigrationStatus,
    UserMigrationStatusFromJSON,
    UserMigrationStatusFromJSONTyped,
    UserMigrationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMigrationStatusUpdateRequest
 */
export interface UserMigrationStatusUpdateRequest {
    /**
     * 
     * @type {UserMigrationStatus}
     * @memberof UserMigrationStatusUpdateRequest
     */
    migrationStatus?: UserMigrationStatus;
}

export function UserMigrationStatusUpdateRequestFromJSON(json: any): UserMigrationStatusUpdateRequest {
    return UserMigrationStatusUpdateRequestFromJSONTyped(json, false);
}

export function UserMigrationStatusUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMigrationStatusUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'migrationStatus': !exists(json, 'migrationStatus') ? undefined : UserMigrationStatusFromJSON(json['migrationStatus']),
    };
}

export function UserMigrationStatusUpdateRequestToJSON(value?: UserMigrationStatusUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'migrationStatus': UserMigrationStatusToJSON(value.migrationStatus),
    };
}


