/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArticleDto,
    ArticleDtoFromJSON,
    ArticleDtoToJSON,
    ArticleListItemDto,
    ArticleListItemDtoFromJSON,
    ArticleListItemDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ApiCmsArticleIdGetRequest {
    id: string;
}

export interface ApiCmsArticleUrlGetRequest {
    url?: string;
}

/**
 * 
 */
export class CmsApi extends runtime.BaseAPI {

    /**
     */
    async apiCmsArticleIdGetRaw(requestParameters: ApiCmsArticleIdGetRequest): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCmsArticleIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiCmsArticleIdGet(requestParameters: ApiCmsArticleIdGetRequest): Promise<ArticleDto> {
        const response = await this.apiCmsArticleIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiCmsArticleUrlGetRaw(requestParameters: ApiCmsArticleUrlGetRequest): Promise<runtime.ApiResponse<ArticleDto>> {
        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/article/url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiCmsArticleUrlGet(requestParameters: ApiCmsArticleUrlGetRequest): Promise<ArticleDto> {
        const response = await this.apiCmsArticleUrlGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiCmsArticlesGetRaw(): Promise<runtime.ApiResponse<Array<ArticleListItemDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/articles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleListItemDtoFromJSON));
    }

    /**
     */
    async apiCmsArticlesGet(): Promise<Array<ArticleListItemDto>> {
        const response = await this.apiCmsArticlesGetRaw();
        return await response.value();
    }

}
