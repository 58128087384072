import React from 'react';
import Page from 'components/page/Page';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { VerifiedUserResponse } from 'api/minside/models';
import { useTranslation, Trans } from 'react-i18next';

interface IProps {
  unverifiedUser: VerifiedUserResponse | undefined;
}

export const ProfileValidationSuccessful = ({ unverifiedUser }: IProps) => {
  const { t } = useTranslation('components');
  return (
    <Page header={t('profileValidate.profileValidationSuccessful.title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            {t('profileValidate.profileValidationSuccessful.userValidationSuccess')}
            <br />
            {unverifiedUser && (
              <Trans t={t} i18nKey='profileValidate.profileValidationSuccessful.validationRemovedFromUser'>
                Brukeren {{ displayName: unverifiedUser.displayName }} med e-post {{ email: unverifiedUser.email }} er
                ikke lenger verifisert.
              </Trans>
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
