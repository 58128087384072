/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageModel
 */
export interface PageModel {
    /**
     * 
     * @type {number}
     * @memberof PageModel
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageModel
     */
    totalCount?: number;
}

export function PageModelFromJSON(json: any): PageModel {
    return PageModelFromJSONTyped(json, false);
}

export function PageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function PageModelToJSON(value?: PageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
    };
}


