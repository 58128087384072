/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SapUserDataResponse
 */
export interface SapUserDataResponse {
    /**
     * 
     * @type {Date}
     * @memberof SapUserDataResponse
     */
    employmentStartDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    initials?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    jobCode2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    personCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sapUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sectionCode?: string | null;
}

export function SapUserDataResponseFromJSON(json: any): SapUserDataResponse {
    return SapUserDataResponseFromJSONTyped(json, false);
}

export function SapUserDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SapUserDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employmentStartDate': !exists(json, 'employmentStartDate') ? undefined : (json['employmentStartDate'] === null ? null : new Date(json['employmentStartDate'])),
        'initials': !exists(json, 'initials') ? undefined : json['initials'],
        'jobCode2': !exists(json, 'jobCode2') ? undefined : json['jobCode2'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'personCity': !exists(json, 'personCity') ? undefined : json['personCity'],
        'sapUserId': !exists(json, 'sapUserId') ? undefined : json['sapUserId'],
        'sectionCode': !exists(json, 'sectionCode') ? undefined : json['sectionCode'],
    };
}

export function SapUserDataResponseToJSON(value?: SapUserDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employmentStartDate': value.employmentStartDate === undefined ? undefined : (value.employmentStartDate === null ? null : value.employmentStartDate.toISOString()),
        'initials': value.initials,
        'jobCode2': value.jobCode2,
        'jobTitle': value.jobTitle,
        'personCity': value.personCity,
        'sapUserId': value.sapUserId,
        'sectionCode': value.sectionCode,
    };
}


