import React from 'react';
import { Button, ButtonLink } from 'common.ui';
import { UserMigrationApplicationResponse, UserMigrationStatus } from 'api/minside';
import { Steps } from 'screens/user-migration/MigrationWizard';
import './MigrationWizard.scss';
import { useTranslation } from 'react-i18next';

type IProps = {
  applications: UserMigrationApplicationResponse[];
  onMigrationCompleted: (status: UserMigrationStatus) => void;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
};

function MigrationWizardIntroduction({ applications, setStep, onMigrationCompleted }: IProps) {
  const { t } = useTranslation('components', { keyPrefix: 'userMigration.migrationWizardIntroduction' });
  return (
    <>
      <p>
        <b>{t('p1')}</b>
      </p>

      <p>{t('p2')}</p>
      <ul>
        {applications.map((app) => (
          <li key={app.applicationId || ''}>{app.name}</li>
        ))}
      </ul>

      <p>{t('p3')}</p>
      <p>{t('p4')}</p>
      <p>{t('p5')}</p>

      {/* <div className='idPortenBox'>
        <h6>ID-porten påkrevd</h6>
        <p>
          For å kunne koble sammen den nye kontoen med eksisterende brukerkontoer hos Miljødirektoratet benytter vi
          ID-porten for å hente informasjon om din arbeidsgiver.
        </p>
      </div> */}

      <Button text={t('btnTransferAccessRights')} onClick={() => setStep(Steps.selection)} />
      <ButtonLink
        text={t('btnCancel')}
        onClick={async () => {
          await onMigrationCompleted(UserMigrationStatus.NotRequired);
          setStep(Steps.completed);
        }}
      />
    </>
  );
}

export default MigrationWizardIntroduction;
