import { organizationGroupsClient } from 'api/MinSideClients';
import { OrganizationGroupResponse, OrganizationGroupSearchOrderBy, SortOrder } from 'api/minside';
import { SelectOption, useFetch } from 'common.ui';

type OrganizationGroupsResult = [SelectOption[] | undefined, OrganizationGroupResponse[] | undefined];

export function useOrganizationGroups(): OrganizationGroupsResult {
  const [fetchedOrganizationGroups] = useFetch<OrganizationGroupsResult>(
    () => fetchAllOrganizationGroups(),
    [undefined, undefined] as OrganizationGroupsResult,
    false,
    []
  );

  const fetchAllOrganizationGroups = async (): Promise<OrganizationGroupsResult> => {
    const organizationGroups = await organizationGroupsClient.apiOrganizationGroupsGet({
      pageNumber: 1,
      pageSize: 9999,
      sortOrder: SortOrder.Ascending,
      orderField: OrganizationGroupSearchOrderBy.Name
    });

    if (!organizationGroups.data) {
      return [undefined, undefined] as OrganizationGroupsResult;
    }

    const options = organizationGroups.data
      .map((d) => {
        return {
          id: d.organizationGroupId?.toString(),
          text: d.name
        } as SelectOption;
      })
      .sort((o1, o2) => (o1.text || '').localeCompare(o2.text || ''));

    const data = organizationGroups.data || [];

    return [options, data];
  };

  return fetchedOrganizationGroups;
}
