import React from 'react';
import Page from 'components/page/Page';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Button, SimpleModal } from 'common.ui';
import { VerifiedUserResponse } from 'api/minside/models';
import { formatUserType } from 'helpers/enumHelpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  showModal: boolean;
  onCancel: () => void;
  userToUnverify: VerifiedUserResponse;
  onUnverify: () => Promise<void>;
}

export const ProfileUnvalidate = ({ showModal, onCancel, userToUnverify, onUnverify }: IProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'profileValidate.profileUnvalidate' });
  return (
    <Page header={t('title')}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            <SimpleModal show={showModal} header={t('removeStatusModal.title')} onCancel={onCancel}>
              <p>
                {t('removeStatusModal.p1')}
                <br />
                {t('removeStatusModal.p2')}
              </p>
              <p>{t('removeStatusModal.p3')}</p>
              <ul>
                <li>
                  {t('removeStatusModal.name')}: {userToUnverify.displayName}
                </li>
                <li>
                  {t('removeStatusModal.email')}: {userToUnverify.email}
                </li>
                <li>
                  {t('removeStatusModal.userType')}:{' '}
                  {userToUnverify?.userType &&
                    formatUserType(
                      userToUnverify.userType,
                      t('removeStatusModal.userTypes.externalUser'),
                      t('removeStatusModal.userTypes.internalUser')
                    )}
                </li>
              </ul>

              <Button showArrow text={t('removeStatusModal.remove')} onClick={onUnverify} />
            </SimpleModal>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
