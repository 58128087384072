/* tslint:disable */
/* eslint-disable */
/**
 * MinSide API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateUserPreferredLanguageRequest
 */
export interface UpdateUserPreferredLanguageRequest {
    /**
     * 
     * @type {Language}
     * @memberof UpdateUserPreferredLanguageRequest
     */
    preferredLanguage?: Language;
}

export function UpdateUserPreferredLanguageRequestFromJSON(json: any): UpdateUserPreferredLanguageRequest {
    return UpdateUserPreferredLanguageRequestFromJSONTyped(json, false);
}

export function UpdateUserPreferredLanguageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserPreferredLanguageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preferredLanguage': !exists(json, 'preferredLanguage') ? undefined : LanguageFromJSON(json['preferredLanguage']),
    };
}

export function UpdateUserPreferredLanguageRequestToJSON(value?: UpdateUserPreferredLanguageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preferredLanguage': LanguageToJSON(value.preferredLanguage),
    };
}


